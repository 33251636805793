import {
  Component,
  Input,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { ICapacityData } from 'src/app/common/management.interfaces';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-places',
  templateUrl: './places.component.html',
  styleUrl: './places.component.css',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule],
})
export class PlacesComponent implements OnChanges, AfterViewInit {
  @Input() displayMode: 'simple' | 'full' = 'simple';
  @Input() data: any[];
  ngAfterViewInit(): void {
    Promise.resolve().then(() => {
      if (this.capacityData) {
        this.ready = true;
      }
    });
  }
  public ready: boolean = false;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.data) {
      this.capacityData = this.buildCapacity(this.data);
    }
  }

  public capacityData: {
    occupied: number;
    total: number;
    percentUsed: number;
    percentFree: number;
  };

  buildCapacity(data: ICapacityData[]): {
    occupied: number;
    total: number;
    percentUsed: number;
    percentFree: number;
  } {
    return data.reduce(
      (acc, curr) => {
        acc.occupied += curr.totalPlaces - curr.availablePlaces;
        acc.total += curr.totalPlaces;
        acc.percentUsed = (acc.occupied / acc.total) * 100;
        acc.percentFree = 100 - acc.percentUsed;
        return acc;
      },
      { occupied: 0, total: 0, percentUsed: 0, percentFree: 0 }
    );
  }
}
