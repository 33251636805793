<div class="container">
  <h2 class="title-page">Votre paiement est validé!</h2>
  <div class="sub-container">
    <div class="child">
      <div class="img-container">
        <img
          src="https://www.ville-boisleroi.fr/sites/boisleroi/files/styles/contenu/public/image/2020-02/passes-navigo-decouverte_edito_0.png?itok=WxOIjURC"
          alt=""
        />
      </div>
      <div style="text-align: center">j'utilise ma carte de transport</div>
    </div>

    <div class="child">
      <div class="img-container">
        <img
          style="width: 195px; padding: 40px"
          src="https://cdsassets.apple.com/live/7WUAS350/images/ios/ios-17-iphone-14-pro-maps-search-location-download-callout.png"
          alt=""
        />
      </div>
      <div style="text-align: center">
        Je telecharge l'application pour acceder au parking avec mon tel
      </div>
    </div>
  </div>
</div>
