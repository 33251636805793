// parking-details.component.ts
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BaseChartDirective } from 'ng2-charts';
import { ChartConfiguration, ChartData } from 'chart.js';
import { ParkingManagementEditComponent } from '../parking-management-edit/parking-management-edit.component';
import { JoinPipe, MapPipe } from 'src/app/common/array-manipulation.pipe';

@Component({
  selector: 'app-parking-details',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatChipsModule,
    MatProgressBarModule,
    BaseChartDirective,
    JoinPipe,
    MapPipe,
  ],
  template: `
    <div class="ticket-container" *ngIf="parking">
      <div class="ticket-card">
        <!-- Header -->
        <div class="card-header">
          <button
            mat-raised-button
            color="primary"
            (click)="editParking(parking)"
          >
            <mat-icon>edit</mat-icon>
            Editer
          </button>
        </div>

        <!-- Tags and Overview -->
        <div class="overview-section">
          <div class="stats-grid">
            <div class="stat-card">
              <span class="stat-label">Places totales</span>
              <span class="stat-value">{{ totalPlaces }}</span>
            </div>
            <div class="stat-card">
              <span class="stat-label">Places occupées</span>
              <span class="stat-value">{{ usedPlaces }}</span>
            </div>
            <div class="stat-card">
              <span class="stat-label">Places disponibles</span>
              <span class="stat-value highlight-available">{{
                availablePlaces
              }}</span>
            </div>
            <div class="stat-card">
              <span class="stat-label">Taux d'occupation</span>
              <span class="stat-value">{{ occupancyPercentage }}%</span>
            </div>
          </div>

          <div class="progress-section">
            <mat-progress-bar
              mode="determinate"
              [value]="occupancyPercentage"
              class="occupancy-bar"
            ></mat-progress-bar>
          </div>
        </div>

        <div class="ticket-content">
          <!-- Title -->
          <h3 class="title">{{ parking?.name || 'Sans nom' }}</h3>

          <!-- Meta Info -->
          <div class="meta-info">
            <p class="address">
              <mat-icon>location_on</mat-icon>
              <span
                >{{ parking?.street }}, {{ parking?.postalCode }},
                {{ parking?.city }}</span
              >
            </p>
            <p class="domains" *ngIf="parking?.cityGroups?.length">
              <mat-icon>domain</mat-icon>
              <span
                >Domaine(s):
                {{ parking?.cityGroups | map: 'name' | join: ', ' }}</span
              >
            </p>
          </div>

          <!-- Capacity Details with Charts -->
          <div class="bike-types-grid">
            <div
              class="bike-type-card"
              *ngFor="let item of parking?.capacity; let i = index"
            >
              <div class="bike-type-info">
                <h4>{{ item.bikeType?.description || 'Type non défini' }}</h4>
                <div class="bike-type-stats">
                  <span>Total: {{ item.totalPlaces }}</span>
                  <span>Disponible: {{ item.availablePlaces }}</span>
                </div>
              </div>
              <div class="bike-type-chart">
                <canvas
                  baseChart
                  [data]="getChartData(item)"
                  [options]="chartOptions"
                  [type]="'doughnut'"
                >
                </canvas>
              </div>
            </div>
          </div>

          <!-- Media Section -->
          <div class="media-section">
            <!-- Photos -->
            <div class="photos-section" *ngIf="parking.pictures?.length">
              <h4>Photos</h4>
              <div class="photos-grid">
                <div class="photo-card" *ngFor="let photo of parking.pictures">
                  <img [src]="photo.url" [alt]="photo.description || ''" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="no-data-container" *ngIf="!parking">
      <p class="no-data-message">
        Sélectionnez un parking pour voir les détails
      </p>
    </div>
  `,
  styles: [
    `
      .ticket-container {
        padding: 20px;
      }

      .ticket-card {
        background: white;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }

      .card-header {
        padding: 16px;
        border-bottom: 1px solid #eee;
      }

      .ticket-tags {
        padding: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;
      }

      .tags-group {
        display: flex;
        gap: 8px;
      }

      .ticket-content {
        padding: 16px;
      }

      .title {
        font-size: 24px;
        font-weight: 500;
        margin: 0 0 16px;
        color: #333;
      }

      .meta-info {
        margin-bottom: 24px;

        p {
          display: flex;
          align-items: center;
          gap: 8px;
          margin: 8px 0;
          color: #666;

          mat-icon {
            font-size: 20px;
            width: 20px;
            height: 20px;
          }
        }
      }

      .capacity-section {
        margin-bottom: 24px;

        h4 {
          font-size: 18px;
          margin: 0 0 16px;
          color: #333;
        }
      }

      .capacity-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 16px;
      }

      .capacity-item {
        background: #f5f5f5;
        padding: 16px;
        border-radius: 8px;

        p {
          margin: 4px 0;
          color: #666;
        }
      }

      .media-section {
        h4 {
          font-size: 18px;
          margin: 0 0 16px;
          color: #333;
        }
      }

      .photos-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        gap: 16px;
        margin-bottom: 24px;
      }

      .photo-card {
        aspect-ratio: 1;
        overflow: hidden;
        border-radius: 8px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .documents-list {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .document-item {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 8px;
        background: #f5f5f5;
        border-radius: 4px;
        color: #666;
      }

      .no-data-container {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 200px;
        background: #f5f5f5;
        border-radius: 8px;
        margin: 16px;
      }

      .no-data-message {
        color: #666;
        font-size: 16px;
      }

      .overview-section {
        padding: 20px;
        background: white;
        border-radius: 8px;
        margin-bottom: 24px;
      }

      .stats-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 16px;
        margin-bottom: 20px;
      }

      .stat-card {
        background: #f8f9fa;
        padding: 16px;
        border-radius: 8px;
        text-align: center;

        .stat-label {
          display: block;
          color: #6b7280;
          font-size: 14px;
          margin-bottom: 8px;
        }

        .stat-value {
          font-size: 24px;
          font-weight: 600;
          color: #111827;

          &.highlight-available {
            color: #059669;
          }
        }
      }

      .progress-section {
        .occupancy-bar {
          height: 8px;
          border-radius: 4px;
        }
      }

      .bike-types-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        gap: 20px;
        margin: 24px 0;
      }

      .bike-type-card {
        background: white;
        border-radius: 8px;
        padding: 16px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        display: flex;
        gap: 16px;

        .bike-type-info {
          flex: 1;

          h4 {
            margin: 0 0 12px;
            color: #374151;
          }

          .bike-type-stats {
            display: flex;
            flex-direction: column;
            gap: 8px;
            color: #6b7280;
          }
        }

        .bike-type-chart {
          width: 100px;
          height: 100px;
        }
      }
    `,
  ],
})
export class ParkingDetailsComponent {
  @Input() parking: any;
  @Output() refreshRequest = new EventEmitter<void>();

  constructor(private dialog: MatDialog) {}

  chartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    // cutout: '70%',
  };

  get totalPlaces(): number {
    return (
      this.parking?.capacity?.reduce(
        (sum: number, item: any) => sum + item.totalPlaces,
        0
      ) || 0
    );
  }

  get availablePlaces(): number {
    return (
      this.parking?.capacity?.reduce(
        (sum: number, item: any) => sum + item.availablePlaces,
        0
      ) || 0
    );
  }

  get usedPlaces(): number {
    return this.totalPlaces - this.availablePlaces;
  }

  get occupancyPercentage(): number {
    return this.totalPlaces
      ? Math.round((this.usedPlaces / this.totalPlaces) * 100)
      : 0;
  }

  getChartData(item: any): ChartData<'doughnut'> {
    const used = item.totalPlaces - item.availablePlaces;
    return {
      labels: ['Occupé', 'Disponible'],
      datasets: [
        {
          data: [used, item.availablePlaces],
          backgroundColor: ['#dc2626', '#059669'],
          borderWidth: 0,
        },
      ],
    };
  }

  editParking(parking: any): void {
    const dialogRef = this.dialog.open(ParkingManagementEditComponent, {
      width: '1200px',
      data: { ...parking },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refreshRequest.emit(result);
        console.log('Updated parking:', result);
      }
    });
  }
}
