<mat-spinner
  *ngIf="displayMode === 'full'"
  diameter="80"
  strokeWidth="6"
  [value]="capacityData.percentUsed"
  mode="determinate"
  color="#f44336"
></mat-spinner>
<div *ngIf="ready" class="placesDiv">
  <span class="capUsed">{{ capacityData.occupied }}</span
  >/<span class="capTotal">{{ capacityData.total }}</span>
</div>
