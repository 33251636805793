import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-table-widget-ticket-status',
  standalone: true,
  imports: [],
  templateUrl: './table-widget-ticket-status.component.html',
  styleUrl: './table-widget-ticket-status.component.css',
})
export class TableWidgetTicketStatusComponent {
  @Input() data: any;
}
