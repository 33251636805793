<div class="flex flex-column padding-40">
  <div class="margin-x-between-20 margin-top-40">
    <div class="flex flex-row margin-x-between-20">
      <div class="flex flex-row container-dashboard">
        <app-dashboard-filling-rate
          class="flex"
          [fillingRateData]="fillingRate"
        ></app-dashboard-filling-rate>
      </div>

      <div class="flex flex-row container-dashboard">
        <app-dashboard-number-of-opening-by-parking
          class="flex flex-column"
          [openingByParkingsData]="openingByParkingsData"
        ></app-dashboard-number-of-opening-by-parking>
      </div>

      <!-- <div class="flex card">
        <app-dashboard-filling-rate
          [fillingRateData]="fillingRate"
        ></app-dashboard-filling-rate>
      </div> -->
    </div>
  </div>

  <div>
    <canvas
      baseChart
      [data]="barChartData"
      [labels]="barChartLabels"
      [options]="barChartOptions"
      [legend]="barChartLegend"
      [type]="barChartType"
      (chartHover)="chartHovered($event)"
      (chartClick)="chartClicked($event)"
    >
    </canvas>
  </div>

  <div class="margin-x-between-20 margin-top-40">
    <div class="flex flex-column">
      <p class="sub-title">Listing des ouvertures</p>
      <div>
        <app-table
          [apiUrl]="apiUrlIdCapt"
          [columns]="tableColumns"
          [pageSize]="pageSize"
          [actions]="actions"
          (actionTriggered)="onActionTriggered($event)"
          [enablePagination]="false"
          [enableActions]="true"
        >
        </app-table>
      </div>
    </div>

    <div class="flex flex-column">
      <p class="sub-title">parking</p>
      <div>
        <app-table
          [apiUrl]="apiUrl"
          [columns]="tableColumns"
          [pageSize]="pageSize"
          [actions]="actions"
          (actionTriggered)="onActionTriggered($event)"
          [enablePagination]="true"
          [enableActions]="true"
        >
        </app-table>
      </div>
    </div>
  </div>
</div>
