import { Routes } from '@angular/router';
import { ParkingComponent } from './parking/parking.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { PricesComponent } from './prices/prices.component';
import { OrdersComponent } from './orders/orders.component';
import { LoginComponent } from './login/login.component';
import { SubscribeComponent } from './subscribe/subscribe.component';
import { SubscriptionValidatedComponent } from './subscription-validated/subscription-validated.component';
import { OrderValidatedComponent } from './order-validated/order-validated.component';
import { AccountComponent } from './account/account.component';
import { AuthGuard } from './auth/auth.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ParkingManagementComponent } from './parking-management/parking-management.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { TicketManagementComponent } from './ticket-management/ticket-management.component';
import { GlobalParametersComponent } from './global-parameters/global-parameters.component';
import { Role } from './auth/roles.enum';

export const routes: Routes = [
  {
    path: '',
    component: ParkingComponent,
    canActivate: [AuthGuard],
    title: 'Parking Page',
  },
  {
    path: 'parking',
    component: ParkingComponent,
    canActivate: [AuthGuard],
    title: 'Parking Page',
  },
  {
    path: 'checkout/:id',
    component: CheckoutComponent,
    title: 'Commande',
  },
  {
    path: 'prices',
    component: PricesComponent,
    title: 'Prix',
  },

  {
    path: 'orders',
    component: OrdersComponent,
    title: 'Mes commandes',
  },

  {
    path: 'login',
    component: LoginComponent,
    title: 'Connexion',
  },
  {
    path: 'subscribe',
    component: SubscribeComponent,
    title: 'Inscription',
  },
  {
    path: 'subscription-validated',
    component: SubscriptionValidatedComponent,
    title: 'Inscription validée',
  },
  {
    path: 'order-validated',
    component: OrderValidatedComponent,
    title: 'Commande validée',
  },

  {
    path: 'account',
    component: AccountComponent,
    canActivate: [AuthGuard],
    title: 'Mon compte',
  },

  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: { requiredRole: Role.ADMIN | Role.SUPERADMIN },
    title: 'Dashboard',
  },
  {
    path: 'parking-management',
    component: ParkingManagementComponent,
    canActivate: [AuthGuard],
    data: { requiredRole: Role.ADMIN | Role.SUPERADMIN },
    title: 'Gestion des parking',
  },
  {
    path: 'user-management',
    component: UserManagementComponent,
    canActivate: [AuthGuard],
    data: { requiredRole: Role.ADMIN | Role.SUPERADMIN },
    title: 'Gestion des utilisateurs',
  },
  {
    path: 'ticket-management',
    component: TicketManagementComponent,
    canActivate: [AuthGuard],
    data: { requiredRole: Role.ADMIN | Role.SUPERADMIN },
    title: 'Gestion des incidents',
  },
  {
    path: 'global-parameters',
    component: GlobalParametersComponent,
    canActivate: [AuthGuard],
    data: { requiredRole: Role.SUPERADMIN },
    title: "Paramètres de l'application",
  },
];
