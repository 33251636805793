<div class="login-container">
  <div class="login-card">
    <h2 class="login-title">Connectez vous</h2>

    <form [formGroup]="loginForm" class="login-form" (ngSubmit)="login()">
      <mat-form-field>
        <mat-label>Votre Email</mat-label>
        <input
          matInput
          type="email"
          formControlName="email"
          placeholder="exemple@email.com"
        />
        <mat-icon matPrefix>email</mat-icon>
        <mat-error *ngIf="loginForm.get('email')?.invalid">
          L'email n'est pas valide
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Votre mot de passe</mat-label>
        <input
          matInput
          [type]="hidePassword ? 'password' : 'text'"
          formControlName="password"
        />
        <mat-icon matPrefix>lock</mat-icon>
        <button
          mat-icon-button
          matSuffix
          type="button"
          (click)="hidePassword = !hidePassword"
        >
          <mat-icon>{{
            hidePassword ? "visibility_off" : "visibility"
          }}</mat-icon>
        </button>
      </mat-form-field>

      <div class="forgot-password">
        <a href="#">Mot de passe oublié?</a>
      </div>

      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="!loginForm.valid || isLoading"
        class="login-button"
      >
        <span *ngIf="!isLoading">Se connecter</span>
        <mat-spinner *ngIf="isLoading" diameter="20"></mat-spinner>
      </button>
    </form>

    <div class="register-prompt">
      <span>Pas encore inscrit?</span>
      <a (click)="goToSubscribePage()" class="register-link">
        Inscription au service
      </a>
    </div>
  </div>
</div>
