import { Component, EventEmitter } from '@angular/core';
import { TableComponent } from '../table/table.component';
import { TicketManagementDetailComponent } from './ticket-management-detail/ticket-management-detail.component';
import { BaseChartDirective } from 'ng2-charts';
import { TableWidgetPriorityComponent } from '../table/table-widgets/table-widget-priority/table-widget-priority.component';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { TicketManagementEditComponent } from './ticket-management-edit/ticket-management-edit.component';
import { TableWidgetParkingComponent } from '../table/table-widgets/table-widget-parking/table-widget-parking.component';
import { TicketChartIncidentsComponent } from './ticket-chart-incidents/ticket-chart-incidents.component';
import { NgClass, NgIf } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ticket-management',
  standalone: true,
  templateUrl: './ticket-management.component.html',
  styleUrl: './ticket-management.component.css',
  imports: [
    NgClass,
    NgIf,
    TableComponent,
    TableWidgetPriorityComponent,
    TableWidgetParkingComponent,
    TicketManagementDetailComponent,
    MatButtonModule,
    TicketChartIncidentsComponent,
    BaseChartDirective,
  ],
})
export class TicketManagementComponent {
  reloadEvent = new EventEmitter<void>();
  constructor(
    private http: HttpClient,
    private dialog: MatDialog
  ) {}
  // selectedItem = null;
  selectedItem: any = null;
  selectFirstAutomatically = false; // New variable to control automatic selection
  hasData = false; // Track if we have data
  apiUrl = environment.apiUrl + '/tickets';
  urlOneTicket = environment.apiUrl + '/tickets';

  tableColumns = [
    // { columnDef: 'id', header: 'id' },
    {
      columnDef: 'priority',
      header: 'Priorité',
      component: 'app-table-widget-priority',
    },
    // {
    //   columnDef: 'parking',
    //   header: 'Parking',
    //   component: 'app-table-widget-parking',
    // },
    { columnDef: 'description', header: 'description' },
    { columnDef: 'type', header: 'type' },
    {
      columnDef: 'status',
      header: 'status',
      component: 'app-table-widget-status',
    },
  ];

  actions = [{ label: 'Detail', call: 'displayDetails' }];
  async displayDetails(data: any) {
    console.log('detailsClicked', data);
    await this.getOneIncident(data.id);
    this.selectedItem = data;
  }

  // In parent components, add the handler:
  onRefreshRequest() {
    this.reloadEvent.emit();
    console.log('refressh');
    // Reload the current selected item
    if (this.selectedItem) {
      // this.getOneIncident(this.selectedItem.id); // for tickets
      // // or
      // this.selectParking(this.selectedItem); // for parking
    }
  }

  onTableItemsFetched(result: { items: any[]; totalItems: number }) {
    this.hasData = result.items.length > 0;
    console.log('this.hasData');
    console.log(this.hasData);

    if (this.selectFirstAutomatically && this.hasData && !this.selectedItem) {
      console.log(result);
      const firstItem = result.items[0];
      console.log(firstItem);
      this.getOneIncident(firstItem.id);
    }
  }

  // Handler for the actionTriggered event
  onActionTriggered(event: { action: string; data: any }) {
    if (event.action && typeof this[event.action] === 'function') {
      this[event.action](event.data);
    } else {
      console.warn(`No handler found for action: ${event.action}`);
    }
  }
  pageSize = 25; //default page size

  // get one ticket
  getOneIncident(ticketId: number) {
    console.log(ticketId);
    if (ticketId) {
      this.getTicketDetails(ticketId).subscribe({
        next: (data) => {
          this.selectedItem = data;
        },
        error: (error) => {
          console.error('Error fetching ticket details:', error);
        },
        complete: () => {
          console.log('Ticket details fetched successfully');
        },
      });
    }
  }

  getTicketDetails(ticketId: number): Observable<any> {
    return this.http.get(`${this.urlOneTicket}/${ticketId}`);
  }

  createTicket(): void {
    const dialogRef = this.dialog.open(TicketManagementEditComponent, {
      width: '1200px',
      data: {
        // date: null,
        // time: null,
        // description: '',
        // priority: 'Medium', // Default priority for new tickets
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.reloadEvent.emit(); // Emit reload event
        this.selectedItem = null; // Reset selected item
        console.log('New ticket created:', result);
        console.log(result);
        // this.tickets.push({ id: Date.now(), ...result }); // Add a unique ID for the new ticket
      }
    });
  }
}
