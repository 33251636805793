<mat-sidenav-container class="sidenav-container">
  @if (isConnected()) {
    <mat-sidenav #sidenav mode="over" [opened]="false" class="sidenav">
      <div class="side-nav-content flex flex-direction-column">
        <div class="brand-menu">
          <img routerLink="/parking" src="assets/logo-ville.png" alt="" />
        </div>
        <div class="flex flex-direction-column">
          <button
            class="menu-item-button"
            mat-fab
            extended
            routerLink="/parking"
          >
            <mat-icon>bike_dock</mat-icon>
            Parkings
          </button>

          <button
            class="menu-item-button"
            mat-fab
            extended
            routerLink="/orders"
          >
            <mat-icon>receipt</mat-icon>
            Mes commandes
          </button>

          <button
            class="menu-item-button"
            mat-fab
            extended
            routerLink="/account"
          >
            <mat-icon>account_circle</mat-icon>
            Mon compte
          </button>

          <button
            class="menu-item-button"
            mat-fab
            extended
            routerLink="/prices"
          >
            <mat-icon>card_membership</mat-icon>
            Souscrire à une formule
          </button>

          <hr />

          <ng-container *appHasRole="Role.ADMIN || Role.SUPERADMIN">
            <button
              class="menu-item-button"
              mat-fab
              extended
              routerLink="/dashboard"
            >
              <mat-icon>dashboard</mat-icon>
              dashboard
            </button>

            <button
              class="menu-item-button"
              mat-fab
              extended
              routerLink="/parking-management"
            >
              <mat-icon>local_parking</mat-icon>
              Gestion des parking
            </button>

            <button
              class="menu-item-button"
              mat-fab
              extended
              routerLink="/user-management"
            >
              <mat-icon>face</mat-icon>
              Gestion des utilisateurs
            </button>

            <button
              class="menu-item-button"
              mat-fab
              extended
              routerLink="/ticket-management"
            >
              <mat-icon>construction</mat-icon>
              Gestion des incidents
            </button>
          </ng-container>

          <!-- SuperAdmin-only menu items -->
          <ng-container *appHasRole="Role.SUPERADMIN">
            <button
              class="menu-item-button"
              mat-fab
              extended
              routerLink="/global-parameters"
            >
              <mat-icon>construction</mat-icon>
              Parametres de l'application
            </button>
          </ng-container>
        </div>

        <button mat-stroked-button (click)="logout()">Se déconnecter</button>
      </div>
    </mat-sidenav>
  }

  <!-- Main Content -->
  <mat-sidenav-content>
    <div class="top">
      <!-- Header with Conditional Hamburger Icon -->
      <div class="header test-text poppins-thin">
        @if (isConnected()) {
          <button mat-icon-button (click)="toggleSidenav()">
            <mat-icon>menu</mat-icon>
          </button>
        }

        <div class="brand">
          <img routerLink="/parking" src="assets/logo-ville.png" alt="" />
        </div>

        @if (isConnected()) {
          <div class="items-container">
            <div *ngIf="firstName">Hello, {{ firstName }}!</div>
            <button routerLink="/account" mat-icon-button>
              <mat-icon>account_circle</mat-icon>
            </button>
          </div>
        }
      </div>

      <!-- Main Container -->
      <div class="maincontainer">
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
