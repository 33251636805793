import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { SubscriptionPlanService } from '../subscription-plan.service';
// import { TicketManagementService } from '../../ticket-management.service';

@Component({
  selector: 'app-subscription-plan-edit',
  standalone: true,
  imports: [
    MatSlideToggleModule,
    ReactiveFormsModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './subscription-plan-edit.component.html',
  styleUrl: './subscription-plan-edit.component.css',
})
export class SubscriptionPlanEditComponent {
  isEdit = false; // Default to creation mode
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<SubscriptionPlanEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private subscriptionPlanService: SubscriptionPlanService
  ) {
    this.isEdit = !!(data && data.id);

    // Initialize form
    this.form = new FormGroup({
      label: new FormControl(data?.label || '', [Validators.required]),
      price: new FormControl(data?.price || '', [Validators.required]),
      duration: new FormControl(data?.duration || '', [Validators.required]),
      currency: new FormControl(data?.currency || '', [Validators.required]),
      enabled: new FormControl(data?.enabled ?? true, [Validators.required]),
    });
  }

  update() {
    let data = { ...this.form.value };

    this.subscriptionPlanService
      .updateSubscriptionPlan(this.data.id, data)
      .subscribe({
        next: () => {
          console.log('Update successful:', data);
          this.dialogRef.close(data); // Close the modal and pass back updated data
        },
        error: (err) => console.log(err),
      });
  }
  create(): void {
    if (this.form.valid) {
      const createData = this.form.value;
      this.subscriptionPlanService
        .createSubscriptionPlan(createData)
        .subscribe({
          next: (createdData) => {
            console.log('Creation successful:', createdData);
            this.dialogRef.close(createdData); // Pass back the created data
          },
          error: (err) => console.error('Error creating:', err),
        });
    }
  }
}
