import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TableComponent } from 'src/app/table/table.component';
import { EventEmitter } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { CityGroupsManagementEditComponent } from './city-groups-management-edit/city-groups-management-edit.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-city-groups-management',
  standalone: true,
  imports: [TableComponent, MatButtonModule],
  templateUrl: './city-groups-management.component.html',
  styleUrl: './city-groups-management.component.css',
})
export class CityGroupsManagementComponent {
  reloadEvent = new EventEmitter<void>(); // EventEmitter for table reload

  constructor(
    private http: HttpClient,
    private dialog: MatDialog
  ) {}
  selectedItem = null;

  apiUrl = environment.apiUrl + '/cityGroups?table_format=true';

  tableColumns = [
    {
      columnDef: 'name',
      header: 'Domaine',
    },
    {
      columnDef: 'city',
      header: 'Ville',
    },

    {
      columnDef: 'parkings',
      header: 'parking',
    },
    {
      columnDef: 'enabled',
      header: 'Enabled',
      displayed: false,
      filter: {
        text: 'Afficher les éléments supprimés',
        type: 'switch',
      },
    },
  ];

  pageSize = 25; //default page size

  actions = [{ label: 'Detail', call: 'displayDetails' }];

  async displayDetails(data: any) {
    this.EditCityGroup(data);
  }

  onTableOrderChanged(newOrder: any[]): void {}

  create(): void {
    this.EditCityGroup();
  }

  EditCityGroup(statusData?: any): void {
    const dialogRef = this.dialog.open(CityGroupsManagementEditComponent, {
      width: '600px',
      data: statusData ? { ...statusData } : null, // Pass a copy of the ticket to avoid mutating the original
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.reloadEvent.emit(); // Emit reload event to refresh the tab
      }
    });
  }

  // Handler for the actionTriggered event
  onActionTriggered(event: { action: string; data: any }) {
    if (event.action && typeof this[event.action] === 'function') {
      this[event.action](event.data);
    } else {
      console.warn(`No handler found for action: ${event.action}`);
    }
  }
}
