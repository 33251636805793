import { Component, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { MatButton } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { CurrencyPipe } from '@angular/common';
import { TableComponent } from '../table/table.component';
import { DashboardFillingRateComponent } from './dashboard-filling-rate/dashboard-filling-rate.component';
import { DashboardNumberOfOpeningByParkingComponent } from './dashboard-number-of-opening-by-parking/dashboard-number-of-opening-by-parking.component';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    BaseChartDirective,
    MatButton,
    MatTableModule,
    CurrencyPipe,
    TableComponent,
    DashboardFillingRateComponent,
    DashboardNumberOfOpeningByParkingComponent,
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective<'pie'> | undefined;

  public barChartOptions = {
    responsive: true,
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        min: 10,
      },
    },
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };

  public barChartLabels = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
  ];
  public barChartType = 'bar';
  public barChartLegend = true;

  public barChartData: ChartData<'bar'> = {
    labels: ['2006', '2007', '2008', '2009', '2010', '2011', '2012'],
    datasets: [
      { data: [65, 59, 80, 81, 56, 55, 40], label: 'Parking' },
      { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' },
    ],
  };

  // events
  public chartClicked({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: object[];
  }): void {
    console.log(event, active);
  }

  public chartHovered({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: object[];
  }): void {}

  /// table
  apiUrl = environment.apiUrl + '/parking';

  apiUrlIdCapt =
    environment.apiUrl +
    '/idcapt/events?startDate=1730333257&endDate=1735603657';

  apiUrlPayementStripe =
    environment.apiUrl +
    '/stripe/payments?startDate=2024-10-01&endDate=2024-10-31&limit=10&sortOrder=desc';

  tableColumns = [
    { columnDef: 'id', header: 'ID' },
    { columnDef: 'event_code', header: 'Code Event' },
    { columnDef: 'date', header: 'Date' },
    { columnDef: 'string_identifier', header: 'Id / badge' },
    { columnDef: 'nom_acces', header: 'client' },
  ];

  tableColumnsPayments = [
    { columnDef: 'id', header: 'ID' },
    { columnDef: 'event_code', header: 'Code Event' },
    { columnDef: 'date', header: 'Date' },
    { columnDef: 'string_identifier', header: 'Id / badge' },
    { columnDef: 'nom_acces', header: 'client' },
  ];

  actions = [
    { label: 'Action 1', call: 'action1' },
    { label: 'Action 2', call: 'action2' },
  ];
  // Define the action functions
  action1(data: any) {
    console.log('Action 1 clicked', data);
  }

  action2(data: any) {
    console.log('Action 2 clicked', data);
  }

  // Handler for the actionTriggered event
  onActionTriggered(event: { action: string; data: any }) {
    if (event.action && typeof this[event.action] === 'function') {
      this[event.action](event.data);
    } else {
      console.warn(`No handler found for action: ${event.action}`);
    }
  }
  pageSize = 5; //default page size

  /**
   * Dashboard tx remplissage
   *
   */
  fillingRate = {
    total: 130,
    occupied: 105,
    vacant: 23,
    previousMonth: {
      total: 130,
      occupied: 10,
      vacant: 120,
    },
  };

  openingByParkingsData = [];
}
