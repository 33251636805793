import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { RouterLink, RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-orders',
  standalone: true,
  imports: [MatCardModule, MatButtonModule, RouterOutlet, RouterLink],
  templateUrl: './orders.component.html',
  styleUrl: './orders.component.css',
})
export class OrdersComponent {
  orders = [
    {
      id: 'pi_3PwlrgEzQUwoKaeW0OytPUy8',
      object: 'payment_intent',
      amount: 1200,
      amount_capturable: 0,
      amount_details: {
        tip: {},
      },
      amount_received: 1200,
      application: null,
      application_fee_amount: null,
      automatic_payment_methods: null,
      canceled_at: null,
      cancellation_reason: null,
      capture_method: 'automatic_async',
      client_secret:
        'pi_3PwlrgEzQUwoKaeW0OytPUy8_secret_y6e7snhsUaPbIpa52pTm8OU5K',
      confirmation_method: 'automatic',
      created: 1725804800,
      currency: 'usd',
      customer: null,
      description: null,
      invoice: null,
      last_payment_error: null,
      latest_charge: 'ch_3PwlrgEzQUwoKaeW0RGM4Ir1',
      livemode: false,
      metadata: {},
      next_action: null,
      on_behalf_of: null,
      payment_method: 'pm_1PwlrfEzQUwoKaeWZ5hYjTb2',
      payment_method_configuration_details: null,
      payment_method_options: {
        card: {
          installments: null,
          mandate_options: null,
          network: null,
          request_three_d_secure: 'automatic',
        },
      },
      payment_method_types: ['card'],
      processing: null,
      receipt_email: null,
      review: null,
      setup_future_usage: null,
      shipping: null,
      source: null,
      statement_descriptor: null,
      statement_descriptor_suffix: null,
      status: 'succeeded',
      transfer_data: null,
      transfer_group: null,
    },
    {
      id: 'pi_3PwkhmEzQUwoKaeW0VFcYiVN',
      object: 'payment_intent',
      amount: 10000,
      amount_capturable: 0,
      amount_details: {
        tip: {},
      },
      amount_received: 10000,
      application: null,
      application_fee_amount: null,
      automatic_payment_methods: null,
      canceled_at: null,
      cancellation_reason: null,
      capture_method: 'automatic_async',
      client_secret:
        'pi_3PwkhmEzQUwoKaeW0VFcYiVN_secret_OBwr9IVws6xkoW5GEnFSg5cuc',
      confirmation_method: 'automatic',
      created: 1725800342,
      currency: 'usd',
      customer: null,
      description: null,
      invoice: null,
      last_payment_error: null,
      latest_charge: 'ch_3PwkhmEzQUwoKaeW0SH2aZ3z',
      livemode: false,
      metadata: {},
      next_action: null,
      on_behalf_of: null,
      payment_method: 'pm_1PwkhlEzQUwoKaeW89WnJk7Z',
      payment_method_configuration_details: null,
      payment_method_options: {
        card: {
          installments: null,
          mandate_options: null,
          network: null,
          request_three_d_secure: 'automatic',
        },
      },
      payment_method_types: ['card'],
      processing: null,
      receipt_email: null,
      review: null,
      setup_future_usage: null,
      shipping: null,
      source: null,
      statement_descriptor: null,
      statement_descriptor_suffix: null,
      status: 'succeeded',
      transfer_data: null,
      transfer_group: null,
    },
    {
      id: 'pi_3PwkboEzQUwoKaeW14fLHgdy',
      object: 'payment_intent',
      amount: 10000,
      amount_capturable: 0,
      amount_details: {
        tip: {},
      },
      amount_received: 10000,
      application: null,
      application_fee_amount: null,
      automatic_payment_methods: null,
      canceled_at: null,
      cancellation_reason: null,
      capture_method: 'automatic_async',
      client_secret:
        'pi_3PwkboEzQUwoKaeW14fLHgdy_secret_gfHxywcfFN9PyUkItUai6JHq7',
      confirmation_method: 'automatic',
      created: 1725799972,
      currency: 'usd',
      customer: null,
      description: null,
      invoice: null,
      last_payment_error: null,
      latest_charge: 'ch_3PwkboEzQUwoKaeW1MrnASqM',
      livemode: false,
      metadata: {},
      next_action: null,
      on_behalf_of: null,
      payment_method: 'pm_1PwkboEzQUwoKaeWRF009HBB',
      payment_method_configuration_details: null,
      payment_method_options: {
        card: {
          installments: null,
          mandate_options: null,
          network: null,
          request_three_d_secure: 'automatic',
        },
      },
      payment_method_types: ['card'],
      processing: null,
      receipt_email: null,
      review: null,
      setup_future_usage: null,
      shipping: null,
      source: null,
      statement_descriptor: null,
      statement_descriptor_suffix: null,
      status: 'succeeded',
      transfer_data: null,
      transfer_group: null,
    },
    {
      id: 'pi_3PwkboEzQUwoKaeW14fLHgdy',
      object: 'payment_intent',
      amount: 10000,
      amount_capturable: 0,
      amount_details: {
        tip: {},
      },
      amount_received: 10000,
      application: null,
      application_fee_amount: null,
      automatic_payment_methods: null,
      canceled_at: null,
      cancellation_reason: null,
      capture_method: 'automatic_async',
      client_secret:
        'pi_3PwkboEzQUwoKaeW14fLHgdy_secret_gfHxywcfFN9PyUkItUai6JHq7',
      confirmation_method: 'automatic',
      created: 1725799972,
      currency: 'usd',
      customer: null,
      description: null,
      invoice: null,
      last_payment_error: null,
      latest_charge: 'ch_3PwkboEzQUwoKaeW1MrnASqM',
      livemode: false,
      metadata: {},
      next_action: null,
      on_behalf_of: null,
      payment_method: 'pm_1PwkboEzQUwoKaeWRF009HBB',
      payment_method_configuration_details: null,
      payment_method_options: {
        card: {
          installments: null,
          mandate_options: null,
          network: null,
          request_three_d_secure: 'automatic',
        },
      },
      payment_method_types: ['card'],
      processing: null,
      receipt_email: null,
      review: null,
      setup_future_usage: null,
      shipping: null,
      source: null,
      statement_descriptor: null,
      statement_descriptor_suffix: null,
      status: 'succeeded',
      transfer_data: null,
      transfer_group: null,
    },
    {
      id: 'pi_3PwkboEzQUwoKaeW14fLHgdy',
      object: 'payment_intent',
      amount: 10000,
      amount_capturable: 0,
      amount_details: {
        tip: {},
      },
      amount_received: 10000,
      application: null,
      application_fee_amount: null,
      automatic_payment_methods: null,
      canceled_at: null,
      cancellation_reason: null,
      capture_method: 'automatic_async',
      client_secret:
        'pi_3PwkboEzQUwoKaeW14fLHgdy_secret_gfHxywcfFN9PyUkItUai6JHq7',
      confirmation_method: 'automatic',
      created: 1725799972,
      currency: 'usd',
      customer: null,
      description: null,
      invoice: null,
      last_payment_error: null,
      latest_charge: 'ch_3PwkboEzQUwoKaeW1MrnASqM',
      livemode: false,
      metadata: {},
      next_action: null,
      on_behalf_of: null,
      payment_method: 'pm_1PwkboEzQUwoKaeWRF009HBB',
      payment_method_configuration_details: null,
      payment_method_options: {
        card: {
          installments: null,
          mandate_options: null,
          network: null,
          request_three_d_secure: 'automatic',
        },
      },
      payment_method_types: ['card'],
      processing: null,
      receipt_email: null,
      review: null,
      setup_future_usage: null,
      shipping: null,
      source: null,
      statement_descriptor: null,
      statement_descriptor_suffix: null,
      status: 'succeeded',
      transfer_data: null,
      transfer_group: null,
    },
    {
      id: 'pi_3PwkboEzQUwoKaeW14fLHgdy',
      object: 'payment_intent',
      amount: 10000,
      amount_capturable: 0,
      amount_details: {
        tip: {},
      },
      amount_received: 10000,
      application: null,
      application_fee_amount: null,
      automatic_payment_methods: null,
      canceled_at: null,
      cancellation_reason: null,
      capture_method: 'automatic_async',
      client_secret:
        'pi_3PwkboEzQUwoKaeW14fLHgdy_secret_gfHxywcfFN9PyUkItUai6JHq7',
      confirmation_method: 'automatic',
      created: 1725799972,
      currency: 'usd',
      customer: null,
      description: null,
      invoice: null,
      last_payment_error: null,
      latest_charge: 'ch_3PwkboEzQUwoKaeW1MrnASqM',
      livemode: false,
      metadata: {},
      next_action: null,
      on_behalf_of: null,
      payment_method: 'pm_1PwkboEzQUwoKaeWRF009HBB',
      payment_method_configuration_details: null,
      payment_method_options: {
        card: {
          installments: null,
          mandate_options: null,
          network: null,
          request_three_d_secure: 'automatic',
        },
      },
      payment_method_types: ['card'],
      processing: null,
      receipt_email: null,
      review: null,
      setup_future_usage: null,
      shipping: null,
      source: null,
      statement_descriptor: null,
      statement_descriptor_suffix: null,
      status: 'succeeded',
      transfer_data: null,
      transfer_group: null,
    },
    {
      id: 'pi_3PwkboEzQUwoKaeW14fLHgdy',
      object: 'payment_intent',
      amount: 10000,
      amount_capturable: 0,
      amount_details: {
        tip: {},
      },
      amount_received: 10000,
      application: null,
      application_fee_amount: null,
      automatic_payment_methods: null,
      canceled_at: null,
      cancellation_reason: null,
      capture_method: 'automatic_async',
      client_secret:
        'pi_3PwkboEzQUwoKaeW14fLHgdy_secret_gfHxywcfFN9PyUkItUai6JHq7',
      confirmation_method: 'automatic',
      created: 1725799972,
      currency: 'usd',
      customer: null,
      description: null,
      invoice: null,
      last_payment_error: null,
      latest_charge: 'ch_3PwkboEzQUwoKaeW1MrnASqM',
      livemode: false,
      metadata: {},
      next_action: null,
      on_behalf_of: null,
      payment_method: 'pm_1PwkboEzQUwoKaeWRF009HBB',
      payment_method_configuration_details: null,
      payment_method_options: {
        card: {
          installments: null,
          mandate_options: null,
          network: null,
          request_three_d_secure: 'automatic',
        },
      },
      payment_method_types: ['card'],
      processing: null,
      receipt_email: null,
      review: null,
      setup_future_usage: null,
      shipping: null,
      source: null,
      statement_descriptor: null,
      statement_descriptor_suffix: null,
      status: 'succeeded',
      transfer_data: null,
      transfer_group: null,
    },
    {
      id: 'pi_3PwkboEzQUwoKaeW14fLHgdy',
      object: 'payment_intent',
      amount: 10000,
      amount_capturable: 0,
      amount_details: {
        tip: {},
      },
      amount_received: 10000,
      application: null,
      application_fee_amount: null,
      automatic_payment_methods: null,
      canceled_at: null,
      cancellation_reason: null,
      capture_method: 'automatic_async',
      client_secret:
        'pi_3PwkboEzQUwoKaeW14fLHgdy_secret_gfHxywcfFN9PyUkItUai6JHq7',
      confirmation_method: 'automatic',
      created: 1725799972,
      currency: 'usd',
      customer: null,
      description: null,
      invoice: null,
      last_payment_error: null,
      latest_charge: 'ch_3PwkboEzQUwoKaeW1MrnASqM',
      livemode: false,
      metadata: {},
      next_action: null,
      on_behalf_of: null,
      payment_method: 'pm_1PwkboEzQUwoKaeWRF009HBB',
      payment_method_configuration_details: null,
      payment_method_options: {
        card: {
          installments: null,
          mandate_options: null,
          network: null,
          request_three_d_secure: 'automatic',
        },
      },
      payment_method_types: ['card'],
      processing: null,
      receipt_email: null,
      review: null,
      setup_future_usage: null,
      shipping: null,
      source: null,
      statement_descriptor: null,
      statement_descriptor_suffix: null,
      status: 'succeeded',
      transfer_data: null,
      transfer_group: null,
    },
    {
      id: 'pi_3PwkboEzQUwoKaeW14fLHgdy',
      object: 'payment_intent',
      amount: 10000,
      amount_capturable: 0,
      amount_details: {
        tip: {},
      },
      amount_received: 10000,
      application: null,
      application_fee_amount: null,
      automatic_payment_methods: null,
      canceled_at: null,
      cancellation_reason: null,
      capture_method: 'automatic_async',
      client_secret:
        'pi_3PwkboEzQUwoKaeW14fLHgdy_secret_gfHxywcfFN9PyUkItUai6JHq7',
      confirmation_method: 'automatic',
      created: 1725799972,
      currency: 'usd',
      customer: null,
      description: null,
      invoice: null,
      last_payment_error: null,
      latest_charge: 'ch_3PwkboEzQUwoKaeW1MrnASqM',
      livemode: false,
      metadata: {},
      next_action: null,
      on_behalf_of: null,
      payment_method: 'pm_1PwkboEzQUwoKaeWRF009HBB',
      payment_method_configuration_details: null,
      payment_method_options: {
        card: {
          installments: null,
          mandate_options: null,
          network: null,
          request_three_d_secure: 'automatic',
        },
      },
      payment_method_types: ['card'],
      processing: null,
      receipt_email: null,
      review: null,
      setup_future_usage: null,
      shipping: null,
      source: null,
      statement_descriptor: null,
      statement_descriptor_suffix: null,
      status: 'succeeded',
      transfer_data: null,
      transfer_group: null,
    },
  ];
}
