// parking-management.component.ts
import { Component, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { TableComponent } from '../table/table.component';
import { ParkingDetailsComponent } from './parking-details/parking-details.component';
import { ParkingManagementEditComponent } from './parking-management-edit/parking-management-edit.component';
import { TicketChartIncidentsComponent } from '../ticket-management/ticket-chart-incidents/ticket-chart-incidents.component';
import { environment } from 'src/environments/environment';
import { ParkingManagementService } from './parking-management.service';
import { firstValueFrom } from 'rxjs';
import { ParkingOccupationChartComponent } from './parking-occupation-chart.component';

@Component({
  selector: 'app-parking-management',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    TableComponent,
    ParkingDetailsComponent,
    TicketChartIncidentsComponent,
    ParkingOccupationChartComponent,
  ],
  template: `
    <div class="padding-40">
      <div class="title-container">
        <div class="flex flex-row justify-content-space-between">
          <div class="flex flex-column">
            <h2 class="title-page">Gestion des Parkings</h2>
            <p class="sub-title">Listing des Parkings</p>
            <button
              class="margin-bottom-20"
              mat-raised-button
              color="primary"
              (click)="createParking()"
            >
              Nouveau parking
            </button>
          </div>
          <div class="flex chart-wrapper">
            <app-parking-occupation-chart
              style="width: 100%"
            ></app-parking-occupation-chart>
          </div>
        </div>
      </div>

      <div
        class="flex flex-row margin-x-between-30"
        [ngClass]="{ 'full-width': !selectFirstAutomatically || !hasData }"
      >
        <div
          class="flex"
          [ngClass]="{ 'full-width': !selectFirstAutomatically || !hasData }"
        >
          <div class="flex flex-column">
            <div>
              <app-table
                [apiUrl]="apiUrl"
                [columns]="tableColumns"
                [pageSize]="pageSize"
                [actions]="actions"
                [reorderable]="false"
                (actionTriggered)="onActionTriggered($event)"
                (tableItemsFetched)="onTableItemsFetched($event)"
                [enablePagination]="true"
                [enableActions]="true"
                [reloadTrigger]="reloadEvent"
              >
              </app-table>
            </div>
          </div>
        </div>

        <div
          class="flex flex-column"
          *ngIf="(selectFirstAutomatically && hasData) || selectedItem"
        >
          <div>
            <app-parking-details
              (refreshRequest)="onRefreshRequest($event)"
              [parking]="selectedItem"
            >
            </app-parking-details>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .full-width {
        width: 100%;
      }

      .flex-row {
        display: flex;
        gap: 30px;

        &.full-width {
          > .flex {
            flex: 1;
          }
        }
      }

      .flex-row:not(.full-width) {
        > .flex:first-child {
          width: 60%;
        }

        > .flex-column:last-child {
          width: 40%;
        }
      }

      @media (max-width: 992px) {
        .flex-row {
          flex-direction: column;

          > .flex,
          > .flex-column {
            width: 100% !important;
          }
        }
      }
    `,
  ],
})
export class ParkingManagementComponent {
  reloadEvent = new EventEmitter<void>();

  selectedItem: any = null;
  selectFirstAutomatically = false;
  hasData = false;

  apiUrl = environment.apiUrl + '/parking?table_format=true';

  pageSize = 25;

  tableColumns = [
    { columnDef: 'name', header: 'Nom' },
    {
      columnDef: 'address',
      header: 'Adresse',
      component: 'app-parking-address',
      useFullData: true,
    },
  ];

  actions = [
    { label: 'Detail', call: 'displayDetails' },
    { label: 'Ouvrir à distance', call: 'openRemotely' },
  ];

  constructor(
    private dialog: MatDialog,
    private parkingManagementService: ParkingManagementService
  ) {}

  onRefreshRequest(result) {
    this.reloadEvent.emit();
    console.log('refresss');
    console.log(result);
    // Reload the current selected item
    // if (this.selectedItem) {
    //   this.selectedItem = result;
    //   // this.getOneIncident(this.selectedItem.id); // for tickets
    //   // // or
    //   // this.selectParking(this.selectedItem); // for parking
    // }
  }

  onTableItemsFetched(result: { items: any[]; totalItems: number }) {
    console.log(result);
    this.hasData = result.items.length > 0;

    if (this.selectFirstAutomatically && this.hasData && !this.selectedItem) {
      const firstItem = result.items[0];
      this.selectedItem = firstItem;
    }
  }

  async displayDetails(data: any) {
    this.selectedItem = data;
  }

  async openRemotely(data: any) {
    try {
      const result = await firstValueFrom(
        this.parkingManagementService.openRemotely(data)
      );
      console.log('Successfully opened:', result);
      // Optionally show a success message
      // this.snackBar.open('Successfully opened', 'Close', { duration: 3000 });
    } catch (error) {
      console.error('Failed to open:', error);
      // Optionally show an error message
      // this.snackBar.open('Failed to open', 'Close', { duration: 3000 });
    }
  }

  onActionTriggered(event: { action: string; data: any }) {
    if (event.action && typeof this[event.action] === 'function') {
      this[event.action](event.data);
    }
  }

  createParking(): void {
    const dialogRef = this.dialog.open(ParkingManagementEditComponent, {
      width: '1200px',
      data: null,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.reloadEvent.emit(); // Emit reload event
        this.selectedItem = null; // Reset selected item
        console.log('New parking created:', result);
      }
    });
  }
}
