<div class="padding-20">
  <h3 class="title-page">
    {{ isEdit ? "Mise a jour du domaine" : "Créer un nouveau domaine" }}
  </h3>

  <form [formGroup]="form">
    <div class="flex flex-row margin-y-between-20">
      <!-- Left Column - Basic Information -->
      <div class="flex flex-column container padding-20">
        <h4 class="sub-title">Informations générales</h4>

        <mat-form-field>
          <mat-label>Nom du domaine</mat-label>
          <input matInput formControlName="name" required />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Ville</mat-label>
          <input matInput formControlName="city" required />
        </mat-form-field>

        <mat-slide-toggle formControlName="enabled" class="margin-top-10">
          {{ form.get("enabled")?.value ? "Activé" : "Désactivé" }}
        </mat-slide-toggle>
      </div>

      <!-- Right Column - Parkings Selection -->
      <div class="flex flex-column container padding-20">
        <h4 class="sub-title">Sélection des parkings</h4>

        <!-- Search field for parkings -->
        <mat-form-field>
          <mat-label>Rechercher un parking</mat-label>
          <input matInput [formControl]="searchControl" />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>

        <!-- Parkings list -->
        <div class="parkings-list">
          @for (parking of filteredParkings$ | async; track parking.id) {
            <mat-checkbox
              [checked]="isParkingSelected(parking.id)"
              (change)="toggleParking(parking.id)"
              class="margin-bottom-10"
            >
              {{ parking.name }}
            </mat-checkbox>
          }
        </div>
      </div>
    </div>

    <!-- Bottom Buttons -->
    <div class="flex justify-content-flex-end margin-top-20">
      <button mat-button (click)="dialogRef.close()">Annuler</button>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="!form.valid"
        (click)="isEdit ? update() : create()"
        class="margin-left-10"
      >
        {{ isEdit ? "Mettre à jour" : "Créer" }}
      </button>
    </div>
  </form>
</div>
