<div class="padding-20">
  <h2 mat-dialog-title>
    {{ isEdit ? "Modifier le Parking" : "Nouveau Parking" }}
  </h2>

  <mat-stepper [linear]="isLinear">
    <!-- Step 1: General Information -->
    <mat-step
      [stepControl]="form.get('informations')"
      label="Informations générales"
    >
      <div class="container padding-20">
        <form [formGroup]="form.get('informations')">
          <div class="flex flex-column">
            <mat-form-field>
              <mat-label>Nom du parking</mat-label>
              <input matInput formControlName="label" required />
            </mat-form-field>

            <mat-form-field>
              <mat-label>Description</mat-label>
              <textarea
                matInput
                formControlName="description"
                required
                rows="3"
              ></textarea>
            </mat-form-field>

            <div class="flex justify-content-flex-end margin-top-20">
              <button mat-button matStepperNext>Suivant</button>
            </div>
          </div>
        </form>
      </div>
    </mat-step>

    <!-- Step 2: Capacity -->
    <mat-step [stepControl]="form.get('capacityGroup')" label="Capacité">
      <div class="container padding-20">
        <form [formGroup]="form.get('capacityGroup')">
          <div formArrayName="capacity" class="flex flex-column">
            @for (capacity of capacityArray.controls; track $index) {
              <div
                [formGroupName]="$index"
                class="capacity-item margin-bottom-20"
              >
                <mat-form-field>
                  <mat-label>Type de vélo</mat-label>
                  <mat-select formControlName="bikeType" required>
                    @for (type of bikeTypes$ | async; track type.id) {
                      <mat-option [value]="type.id">{{
                        type.label
                      }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Places totales</mat-label>
                  <input
                    matInput
                    type="number"
                    formControlName="totalPlaces"
                    required
                  />
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Places disponibles</mat-label>
                  <input
                    matInput
                    type="number"
                    formControlName="availablePlaces"
                    required
                  />
                </mat-form-field>

                <button
                  mat-icon-button
                  type="button"
                  color="warn"
                  (click)="removeCapacity($index)"
                  [disabled]="capacityArray.length === 1"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            }

            <button
              mat-stroked-button
              type="button"
              (click)="addCapacity()"
              class="margin-bottom-20"
            >
              <mat-icon>add</mat-icon> Ajouter une capacité
            </button>
          </div>

          <div class="flex justify-content-flex-end margin-top-20">
            <button mat-button matStepperPrevious class="margin-right-10">
              Précédent
            </button>
            <button mat-button matStepperNext>Suivant</button>
          </div>
        </form>
      </div>
    </mat-step>
    <!-- Step 3: Access and Status -->
    <mat-step [stepControl]="form.get('access')" label="Accès et status">
      <div class="container padding-20">
        <form [formGroup]="form.get('access')">
          <div class="flex flex-column">
            <mat-form-field>
              <mat-label>Type d'accès</mat-label>
              <mat-select formControlName="accessType" required>
                @for (type of accessTypes$ | async; track type.id) {
                  <mat-option [value]="type.id">{{ type.label }}</mat-option>
                }
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Status</mat-label>
              <mat-select formControlName="status" required>
                @for (status of parkingStatus$ | async; track status.id) {
                  <mat-option [value]="status.id">{{
                    status.label
                  }}</mat-option>
                }
              </mat-select>
            </mat-form-field>

            <mat-slide-toggle formControlName="enabled" class="margin-top-10">
              {{ form.get("access.enabled")?.value ? "Activé" : "Désactivé" }}
            </mat-slide-toggle>
          </div>

          <div class="flex justify-content-flex-end margin-top-20">
            <button mat-button matStepperPrevious class="margin-right-10">
              Précédent
            </button>
            <button mat-button matStepperNext>Suivant</button>
          </div>
        </form>
      </div>
    </mat-step>

    <!-- Step 4: Location -->
    <mat-step [stepControl]="form.get('location')" label="Localisation">
      <div class="container padding-20">
        <div class="location-container">
          <!-- Left side - Address form -->
          <div class="address-form">
            <form [formGroup]="form.get('location')">
              <div class="flex flex-column">
                <mat-form-field>
                  <mat-label>Rue</mat-label>
                  <input matInput formControlName="street" required />
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Ville</mat-label>
                  <input matInput formControlName="city" required />
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Code Postal</mat-label>
                  <input matInput formControlName="postalCode" required />
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Pays</mat-label>
                  <input matInput formControlName="country" required />
                </mat-form-field>

                <button
                  mat-raised-button
                  color="primary"
                  type="button"
                  (click)="detectLocation()"
                  class="margin-bottom-20"
                >
                  <mat-icon>my_location</mat-icon>
                  Détecter la localisation
                </button>
              </div>
            </form>
          </div>

          <!-- Right side - Map -->
          <div class="map-container">
            <google-map
              *ngIf="center"
              [center]="center"
              [zoom]="zoom"
              (mapClick)="addMarker($event)"
              height="400px"
              width="100%"
            >
              <map-marker
                *ngIf="markerPosition"
                [position]="markerPosition"
                [options]="markerOptions"
                (mapDragend)="markerDragEnd($event)"
              >
              </map-marker>
            </google-map>
          </div>
        </div>

        <div class="flex justify-content-flex-end margin-top-20">
          <button mat-button matStepperPrevious class="margin-right-10">
            Précédent
          </button>
          <button mat-button matStepperNext>Suivant</button>
        </div>
      </div>
    </mat-step>
    <!-- Step 5: Equipment -->
    <mat-step [stepControl]="form.get('equipment')" label="Équipement">
      <div class="container padding-20">
        <form [formGroup]="form.get('equipment')">
          <div class="flex flex-column">
            <mat-form-field>
              <mat-label>ID caméra</mat-label>
              <input
                matInput
                type="number"
                formControlName="cameraId"
                min="0"
                required
              />
              <mat-error
                *ngIf="form.get('equipment.cameraId')?.errors?.['min']"
              >
                L'ID caméra ne peut pas être négatif
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>ID lecteur ID capt</mat-label>
              <input
                matInput
                type="number"
                formControlName="readerId"
                min="0"
                required
              />
              <mat-error
                *ngIf="form.get('equipment.readerId')?.errors?.['min']"
              >
                L'ID lecteur ne peut pas être négatif
              </mat-error>
            </mat-form-field>
          </div>

          <div class="flex justify-content-flex-end margin-top-20">
            <button mat-button matStepperPrevious class="margin-right-10">
              Précédent
            </button>
            <button
              mat-raised-button
              color="primary"
              (click)="isEdit ? update() : create()"
              [disabled]="!form.valid"
            >
              {{ isEdit ? "Mettre à jour" : "Créer" }}
              <mat-spinner
                *ngIf="loading"
                diameter="20"
                class="button-spinner"
              ></mat-spinner>
            </button>
          </div>
        </form>
      </div>
    </mat-step>

    <!-- Step 6: Photos, documents -->
    <mat-step [stepControl]="form.get('filesStep')" label="Photos, documents">
      <form [formGroup]="form.get('filesStep')">
        <div *ngIf="isEdit" class="files-container">
          <h4 class="mb-6 text-xl font-medium">Fichiers attachés</h4>

          <div class="flex gap-8">
            <!-- Pictures Section -->
            <div class="flex-1">
              <div class="upload-section">
                <div class="section-header">
                  <h5 class="text-lg font-medium text-gray-700">Images</h5>
                  <button
                    mat-fab
                    color="primary"
                    class="upload-fab-button"
                    (click)="fileInput.nativeElement.click()"
                  >
                    <mat-icon>add</mat-icon>
                  </button>
                  <input
                    #fileInput
                    type="file"
                    (change)="onFileSelected($event, 'picture')"
                    accept="image/*"
                    multiple
                  />
                </div>

                <div class="files-grid">
                  <div
                    *ngFor="let picture of pictures; trackBy: trackByFileId"
                    class="file-card"
                  >
                    <div
                      class="image-container"
                      (click)="openFile(picture, 'picture')"
                    >
                      <mat-spinner
                        *ngIf="!picture.loaded && !picture.error"
                        diameter="40"
                      ></mat-spinner>
                      <img
                        [src]="getFileUrl(picture.fileId)"
                        [alt]="picture.originalName"
                        class="file-thumbnail"
                        (load)="onImageLoad(picture)"
                        (error)="onImageError(picture)"
                        [ngClass]="{ hidden: !picture.loaded }"
                      />
                      <mat-icon
                        *ngIf="picture.error"
                        class="error-icon broken-image"
                        >broken_image</mat-icon
                      >
                    </div>
                    <span class="file-name">{{ picture.originalName }}</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Documents Section -->
            <div class="flex-1">
              <div class="upload-section">
                <div class="section-header">
                  <h5 class="text-lg font-medium text-gray-700">Documents</h5>
                  <button
                    mat-fab
                    color="primary"
                    class="upload-fab-button"
                    (click)="documentInput.nativeElement.click()"
                  >
                    <mat-icon>add</mat-icon>
                  </button>
                  <input
                    #documentInput
                    type="file"
                    (change)="onFileSelected($event, 'file')"
                    multiple
                    accept="image/*"
                  />
                </div>

                <div class="files-grid">
                  <div
                    *ngFor="let document of documents; trackBy: trackByFileId"
                    class="file-card"
                  >
                    <div
                      class="doc-container"
                      (click)="openFile(document, 'file')"
                    >
                      <mat-icon class="doc-icon">description</mat-icon>
                      <button
                        mat-mini-fab
                        color="warn"
                        class="delete-button"
                        (click)="deleteFile(document.fileId, 'file')"
                      >
                        <mat-icon>close</mat-icon>
                      </button>
                    </div>
                    <span class="file-name">{{ document.originalName }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex justify-content-flex-end margin-top-20">
          <button mat-button matStepperPrevious class="margin-right-10">
            Précédent
          </button>
          <button
            mat-raised-button
            color="primary"
            (click)="isEdit ? update() : create()"
            [disabled]="!form.valid"
          >
            {{ isEdit ? "Mettre à jour" : "Créer" }}
            <mat-spinner
              *ngIf="loading"
              diameter="20"
              class="button-spinner"
            ></mat-spinner>
          </button>
        </div>
      </form>
    </mat-step>
  </mat-stepper>
</div>
