import {
  Component,
  Input,
  ViewChild,
  ViewContainerRef,
  Injector,
} from '@angular/core';
import {
  GoogleMapsModule,
  MapAdvancedMarker,
  MapInfoWindow,
} from '@angular/google-maps';
import { InfoWindowContentComponent } from '../info-window-content/info-window-content.component';
import { Parking } from '../parking/parking.interface';

@Component({
  selector: 'app-cartography',
  standalone: true,
  imports: [GoogleMapsModule, InfoWindowContentComponent],
  template: `
    <google-map height="100%" width="100%" [options]="options">
      @for (location of locations; track location.id) {
        <map-advanced-marker
          #markerElem="mapAdvancedMarker"
          [position]="{
            lat: location.location.latitude,
            lng: location.location.longitude,
          }"
          [title]="location.name"
          [id]="location.id"
          (mapClick)="onMarkerClick(markerElem, location)"
        />
      }

      <map-info-window>
        <ng-template #infoWindowContainer></ng-template>
      </map-info-window>
    </google-map>
  `,
  styles: [
    `
      :host {
        display: block;
        height: 100%;
        width: 100%;
      }
    `,
  ],
})
export class CartographyComponent {
  @Input({ required: true }) locations!: Parking[];
  @ViewChild(MapInfoWindow, { static: false }) infoWindow!: MapInfoWindow;
  @ViewChild('infoWindowContainer', { read: ViewContainerRef })
  infoWindowContainer!: ViewContainerRef;

  options: google.maps.MapOptions = {
    mapId: '17d095ba82db7925',
    center: { lat: 45.187635, lng: 5.723455 }, // Default center
    zoom: 15,
  };

  constructor(private injector: Injector) {}

  ngOnInit() {
    if (this.locations?.length > 0) {
      // Calculate center based on actual parking locations
      const bounds = new google.maps.LatLngBounds();
      this.locations.forEach((location) => {
        bounds.extend({
          lat: location.location.latitude,
          lng: location.location.longitude,
        });
      });
      this.options = {
        ...this.options,
        center: bounds.getCenter().toJSON(),
      };
    }
  }

  onMarkerClick(marker: MapAdvancedMarker, parkingData: Parking) {
    // Clear previous content
    this.infoWindowContainer.clear();

    // Create the info window component
    const componentRef = this.infoWindowContainer.createComponent(
      InfoWindowContentComponent,
      {
        injector: this.injector,
      }
    );

    // Pass the full parking data to the info window
    componentRef.instance.data = parkingData;

    // Open the info window
    if (this.infoWindow) {
      this.infoWindow.open(marker);
    }
  }
}
