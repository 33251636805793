import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { User } from './user.interface';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  private apiUrl = environment.apiUrl;
  private apiRouteUsers = 'users';
  private apiRouteCityGroups = 'cityGroups';

  constructor(private http: HttpClient) {}

  getUsers(
    page: number = 0,
    size: number = 10,
    sort?: string,
    direction: 'asc' | 'desc' = 'asc'
  ): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString());

    if (sort) {
      params = params.set('sort', sort).set('direction', direction);
    }

    return this.http.get(`${this.apiUrl}/${this.apiRouteUsers}`, { params });
  }

  getUserById(id: string): Observable<User> {
    return this.http.get<User>(`${this.apiUrl}/${this.apiRouteUsers}/${id}`);
  }

  createUser(user: Partial<User>): Observable<User> {
    return this.http.post<User>(`${this.apiUrl}/${this.apiRouteUsers}`, user);
  }

  updateUser(id: string, user: Partial<User>): Observable<User> {
    return this.http.put<User>(
      `${this.apiUrl}/${this.apiRouteUsers}/${id}`,
      user
    );
  }

  toggleUserStatus(id: string, isActive: boolean): Observable<User> {
    return this.http.patch<User>(
      `${this.apiUrl}/${this.apiRouteUsers}/${id}/status`,
      { isActive }
    );
  }

  updateUserRoles(id: string, roles: string[]): Observable<User> {
    return this.http.patch<User>(
      `${this.apiUrl}/${this.apiRouteUsers}/${id}/roles`,
      { roles }
    );
  }

  getRoles(): Observable<string[]> {
    return this.http.get<string[]>(`${this.apiUrl}/roles`);
  }

  getCityGroups(): Observable<any> {
    return this.http.get(`${this.apiUrl}/${this.apiRouteCityGroups}`);
  }
}
