import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

export enum Status {
  PENDING = 'En attente',
  IN_PROGRESS = 'En cours',
  DONE = 'terminé',
  CANCELED = 'Annulé',
}

export enum Priorities {
  HIGH = 'Important',
  NORMAL = 'Normal',
  URGENT = 'Urgent',
  LOW = 'Non prioritaire',
}

@Component({
  selector: 'app-table-widget-priority',
  standalone: true,
  imports: [NgClass],
  templateUrl: './table-widget-priority.component.html',
  styleUrl: './table-widget-priority.component.css',
})
export class TableWidgetPriorityComponent {
  statuses = Object.values(Status); // Example statuses to loop through
  status: Status = Status.PENDING; // Example single status

  priorities = Object.values(Priorities); // Example statuses to loop through
  priority: Priorities = Priorities.NORMAL; // Example single status
  @Input() data: any;

  getPriorityClass(priority: Priorities): string {
    switch (priority) {
      case Priorities.NORMAL:
        return 'pill-bkg-grey';
      case Priorities.URGENT:
        return 'pill-bkg-red';
      case Priorities.LOW:
        return 'pill-bkg-green';
      case Priorities.HIGH:
        return 'pill-bkg-orange';
      default:
        return 'pill-bkg-grey';
    }
  }
}
