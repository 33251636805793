// parking.component.ts
import { Component, OnInit } from '@angular/core';
import { CartographyComponent } from '../cartography/cartography.component';
import { ParkingService } from './parking.service';
import { Parking } from './parking.interface';

@Component({
  selector: 'app-parking',
  standalone: true,
  imports: [CartographyComponent],
  template: ` <app-cartography [locations]="mappedLocations" /> `,
})
export class ParkingComponent implements OnInit {
  mappedLocations: any[] = [];

  constructor(private parkingService: ParkingService) {}

  ngOnInit() {
    this.parkingService.getParkings().subscribe(
      (parkings: Parking[]) => {
        this.mappedLocations = this.mapParkingsToLocations(parkings);
      },
      (error) => {
        console.error('Error fetching parkings:', error);
      }
    );
  }

  private mapParkingsToLocations(parkings: Parking[]): any[] {
    return parkings.map((parking) => ({
      ...parking,
      lat: parking.location.latitude,
      lng: parking.location.longitude,
      label: {
        color: this.getStatusColor(parking.status.label),
      },
      types: parking.capacity.map((cap) => ({
        type: `${cap.availablePlaces > 0 ? 'O' : 'X'} | ${cap.bikeType.label}`,
        status: cap.availablePlaces > 0 ? 1 : 0,
      })),
    }));
  }

  private getStatusColor(status: string): string {
    // Adjust colors based on your status types
    switch (status.toLowerCase()) {
      case 'maintenance':
        return 'red';
      case 'available':
        return 'green';
      default:
        return 'yellow';
    }
  }
}
