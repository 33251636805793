import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TableComponent } from 'src/app/table/table.component';
// import { TicketStatusEditComponent } from './ticket-status-edit/ticket-status-edit.component';
import { EventEmitter } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ParkingBikeTypeEditComponent } from './parking-bike-type-edit/parking-bike-type-edit.component';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-parking-bike-type',
  standalone: true,
  imports: [TableComponent, MatButtonModule],
  templateUrl: './parking-bike-type.component.html',
  styleUrl: './parking-bike-type.component.css',
})
export class ParkingBikeTypeComponent {
  reloadEvent = new EventEmitter<void>(); // EventEmitter for table reload

  constructor(
    private http: HttpClient,
    private dialog: MatDialog
  ) {}
  selectedItem = null;

  apiUrl = environment.apiUrl + '/parking/bikeType?table_format=true';

  tableColumns = [
    {
      columnDef: 'label',
      header: 'label',
    },

    {
      columnDef: 'description',
      header: 'description',
    },

    {
      columnDef: 'enabled',
      header: 'Enabled',
      displayed: false,
      filter: {
        text: 'Afficher les éléments supprimés',
        type: 'switch',
      },
    },
  ];

  pageSize = 25; //default page size

  actions = [{ label: 'Detail', call: 'displayDetails' }];

  async displayDetails(data: any) {
    this.editParkingBikeTypes(data);
  }

  onTableOrderChanged(newOrder: any[]): void {}

  create(): void {
    this.editParkingBikeTypes();
  }

  editParkingBikeTypes(statusData?: any): void {
    const dialogRef = this.dialog.open(ParkingBikeTypeEditComponent, {
      width: '600px',
      data: statusData ? { ...statusData } : null, // Pass a copy of the ticket to avoid mutating the original
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.reloadEvent.emit(); // Emit reload event to refresh the tab
      }
    });
  }

  // Handler for the actionTriggered event
  onActionTriggered(event: { action: string; data: any }) {
    if (event.action && typeof this[event.action] === 'function') {
      this[event.action](event.data);
    } else {
      console.warn(`No handler found for action: ${event.action}`);
    }
  }
}
