<div class="flex flex-column component-content">
  <div class="dashboard-item-title">Taux de remplissage</div>
  <div class="flex flex-row">
    <div class="flex flex-column">
      <canvas
        baseChart
        [data]="doughnutChartData"
        [options]="chartOptions"
        [type]="doughnutChartType"
      >
      </canvas>
      <div class="flex">+1,4% par rapport au mois précédent</div>
    </div>
    <div class="flex flex-column legend">
      <div><span class="big-number">130</span> place totales</div>
      <div><span class="big-number color-green">105</span> place utilisées</div>
      <div><span class="big-number color-grey">23</span> place vacantes</div>
    </div>
  </div>
</div>
