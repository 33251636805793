import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { environment } from '../../environments/environment';
// import { Status } from './ticket-status/ticket-status.interface';
@Injectable({
  providedIn: 'root',
})
export class SubscriptionPlanService {
  private apiUrl = environment.apiUrl;
  private apiRouteSubscriptionPlan = 'subscription-plans';

  constructor(private http: HttpClient) {}

  updateSubscriptionPlan(id: string, data: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .patch<{
        subscriptionPlan: any;
      }>(this.apiUrl + '/' + this.apiRouteSubscriptionPlan + '/' + id, data, {
        headers,
      })
      .pipe(tap((response) => {}));
  }

  createSubscriptionPlan(data: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .post<{
        subscriptionPlan: any;
      }>(this.apiUrl + '/' + this.apiRouteSubscriptionPlan, data, {
        headers,
      })
      .pipe(tap((response) => {}));
  }
}
