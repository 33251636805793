import { HttpClient, HttpResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { StripeFactoryService, StripeInstance } from 'ngx-stripe';
import { switchMap, Observable } from 'rxjs';
import { LoginService } from '../login/login.service';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { environment } from '../../environments/environment';

interface SubscriptionPlan {
  id: string;
  label: string;
  currency: string;
  price: number;
  duration: number;
  enabled: boolean;
}

interface SubscriptionResponse {
  totalItems: number;
  items: SubscriptionPlan[];
}

interface IStripeSession {
  id: string;
}

@Component({
  selector: 'app-prices',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    RouterOutlet,
    RouterLink,
    MatIconModule,
  ],
  template: `
    <div class="container">
      <h2 class="title-page">Choisissez la formule de votre choix</h2>
      <p class="sub-title">
        A la journée au mois ou à l'année, choisissez la formule qui vous
        convient
      </p>

      <div class="sub-container">
        <ng-container *ngIf="subscriptionPlans?.items">
          <div class="child" *ngFor="let plan of subscriptionPlans.items">
            <mat-card
              appearance="outlined"
              [class.disabled-plan]="!plan.enabled"
            >
              <span class="status-badge" *ngIf="!plan.enabled"
                >Indisponible</span
              >
              <span class="popular-badge" *ngIf="plan.duration >= 720"
                >Populaire</span
              >
              <mat-card-header>
                <mat-card-title>{{ plan.label }}</mat-card-title>
                <mat-card-subtitle>
                  Valable jusqu'au {{ getExpiryDate(plan.duration) }}
                </mat-card-subtitle>
              </mat-card-header>

              <div class="price-amount">
                <span class="currency">€</span>
                {{ plan.price }}
                <span class="period">
                  {{
                    plan.duration < 24
                      ? '/heure'
                      : plan.duration < 168
                        ? '/jour'
                        : plan.duration < 720
                          ? '/semaine'
                          : '/mois'
                  }}
                </span>
              </div>

              <ul class="features">
                <li class="feature-item">Accès instantané</li>
                <li class="feature-item">Support client 24/7</li>
                <li class="feature-item">Annulation flexible</li>
              </ul>

              <mat-card-actions>
                <button
                  mat-button
                  (click)="pay(plan)"
                  [disabled]="!plan.enabled"
                >
                  {{ plan.enabled ? 'Commander' : 'Indisponible' }}
                </button>
              </mat-card-actions>
            </mat-card>
          </div>
        </ng-container>
      </div>
    </div>
  `,
  styleUrl: './prices.component.css',
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricesComponent implements OnInit {
  todayDate: string | undefined;
  tomorrowDate: string | undefined;
  dateInOneMonth: string | undefined;
  dateInOneYear: string | undefined;
  subscriptionPlans: SubscriptionResponse | null = null;
  private stripePublicKey: string = environment.stripePublicKey;
  private apiUrl = environment.apiUrl;

  parkingData: any;

  public stripe!: StripeInstance;
  isLoading: boolean = false;

  private route = inject(ActivatedRoute);
  private router = inject(Router);

  constructor(
    private http: HttpClient,
    private stripeFactory: StripeFactoryService,
    private loginService: LoginService
  ) {}

  ngOnInit(): void {
    const navigation = this.router.getCurrentNavigation();
    this.parkingData = navigation?.extras.state?.['parking'];

    // Fallback: Get data from localStorage if not in navigation state
    if (!this.parkingData) {
      const savedData = localStorage.getItem('selectedParkingData');
      this.parkingData = savedData ? JSON.parse(savedData) : null;
    }

    this.stripe = this.stripeFactory.create(this.stripePublicKey);

    // Fetch subscription plans
    this.http
      .get<SubscriptionResponse>(environment.apiUrl + '/subscription-plans')
      .subscribe((response) => {
        this.subscriptionPlans = response;
      });
  }

  getExpiryDate(hours: number): string {
    const date = new Date();
    date.setHours(date.getHours() + hours);
    return this.getFormattedDate(date);
  }

  // Method to format date as DD/MM/YYYY
  getFormattedDate(date: Date): string {
    const day = this.padZero(date.getDate());
    const month = this.padZero(date.getMonth() + 1);
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  // Helper method to pad single digits with leading zero
  padZero(value: number): string {
    return value < 10 ? '0' + value : value.toString();
  }

  pay(plan: SubscriptionPlan) {
    if (!this.loginService.isConnected()) {
      this.router.navigate(['login'], {
        state: { name: 'pay' },
      });
      return;
    }

    this.isLoading = true;

    this.http
      .post(
        this.apiUrl + '/orders/create-checkout-session',
        {
          amount: plan.price * 100,
          productName: plan.label,
        },
        { observe: 'response' }
      )
      .pipe(
        switchMap((response: HttpResponse<Object>) => {
          const session: IStripeSession = response.body as IStripeSession;
          return this.stripe.redirectToCheckout({ sessionId: session.id });
        })
      )
      .subscribe((result) => {
        if (result.error) {
          console.log(result.error);
        }
        this.isLoading = false;
      });
  }
}
