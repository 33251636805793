import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageCarouselComponent } from '../parking/image.carousel.component';
import { Parking } from '../parking/parking.interface';
import { Router } from '@angular/router';
import { BaseChartDirective } from 'ng2-charts';
import { ChartConfiguration, ChartData } from 'chart.js';

@Component({
  selector: 'app-info-window-content',
  standalone: true,
  imports: [CommonModule, ImageCarouselComponent, BaseChartDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="info-window-content">
      <h2 class="title">{{ data.name }}</h2>
      <p class="address">{{ data.street }}</p>

      <div class="carousel-container">
        <app-image-carousel [images]="data.pictures" />
      </div>

      <div class="capacity-info">
        <p class="available-places">
          Places disponibles: {{ availablePlacesText }}
        </p>

        <!-- Capacity Details with Mini Charts -->
        <div class="bike-types-grid">
          <div
            class="bike-type-card"
            *ngFor="let item of chartDataArray; trackBy: trackByFn"
          >
            <div class="bike-type-info">
              <h4>{{ item.label }}</h4>
              <div class="bike-type-stats">
                <span>{{ item.availableText }}</span>
              </div>
            </div>
            <div class="bike-type-chart">
              <canvas
                baseChart
                [data]="item.chartData"
                [options]="chartOptions"
                [type]="'doughnut'"
              >
              </canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="footer">
        <button class="reserve-btn" (click)="onReserve()">
          <i class="material-icons">local_parking</i>
          <span>Reserver</span>
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      .bike-types-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
        gap: 12px;
        margin-top: 12px;
      }

      .bike-type-card {
        background: #f8f9fa;
        border-radius: 8px;
        padding: 12px;
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .bike-type-info {
        flex: 1;

        h4 {
          margin: 0 0 4px;
          font-size: 13px;
          color: #374151;
          font-weight: 500;
        }

        .bike-type-stats {
          font-size: 12px;
          color: #6b7280;
        }
      }

      .bike-type-chart {
        width: 40px;
        height: 40px;
        flex-shrink: 0;
      }
      .info-window-content {
        padding: 20px;
        max-width: 400px;
        background: white;
        border-radius: 12px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      }

      .current-position {
        font-size: 14px;
        color: #666;
        margin-bottom: 16px;
        padding-bottom: 12px;
        border-bottom: 1px solid #eee;
      }

      .title {
        font-size: 24px;
        color: #0b3725;
        font-weight: 600;
        margin: 0 0 4px;
        line-height: 1.2;
      }

      .address {
        color: #666;
        margin: 0 0 16px;
        font-size: 15px;
      }

      .carousel-container {
        margin: 0 -20px;
        margin-bottom: 20px;
      }

      .capacity-info {
        margin: 16px 0;
      }

      .available-places {
        font-size: 16px;
        color: #0b3725;
      }

      .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 24px;
      }

      .info-group {
        display: flex;
        gap: 24px;
      }

      .info-item {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #666;
        font-size: 16px;
        font-weight: 500;
      }

      .info-item i {
        color: #0b3725;
        font-size: 20px;
      }

      .reserve-btn {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 10px 20px;
        background-color: #6750a4;
        color: white;
        border: none;
        border-radius: 100px;
        cursor: pointer;
        transition: all 0.2s ease;
        font-size: 15px;
        font-weight: 500;
      }

      .reserve-btn:hover {
        background-color: #7b61ff;
        transform: translateY(-1px);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }

      .reserve-btn i {
        font-size: 18px;
      }
    `,
  ],
})
export class InfoWindowContentComponent {
  @Input() data!: Parking;

  chartDataArray: Array<{
    label: string;
    availableText: string;
    chartData: ChartData<'doughnut'>;
  }> = [];

  availablePlacesText = '';
  estimatedTime = 15;
  distance = '2.5';

  readonly chartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
    },
    // cutout: '70%',
    animation: false, // Disable animations for better performance
  };

  constructor(private router: Router) {}

  getChartData(item: any): ChartData<'doughnut'> {
    const used = item.totalPlaces - item.availablePlaces;
    return {
      labels: ['Occupé', 'Disponible'],
      datasets: [
        {
          data: [used, item.availablePlaces],
          backgroundColor: ['#dc2626', '#059669'],
          borderWidth: 0,
        },
      ],
    };
  }

  ngOnInit() {
    this.initializeData();
  }

  private initializeData() {
    // Calculate total places text once
    const totalAvailable = this.data.capacity.reduce(
      (sum, cap) => sum + cap.availablePlaces,
      0
    );
    const totalPlaces = this.data.capacity.reduce(
      (sum, cap) => sum + cap.totalPlaces,
      0
    );
    this.availablePlacesText = `${totalAvailable} sur ${totalPlaces}`;

    // Pre-calculate all chart data
    this.chartDataArray = this.data.capacity.map((item) => ({
      label: item.bikeType?.label || 'Non défini',
      availableText: `${item.availablePlaces}/${item.totalPlaces}`,
      chartData: {
        labels: ['Occupé', 'Disponible'],
        datasets: [
          {
            data: [
              item.totalPlaces - item.availablePlaces,
              item.availablePlaces,
            ],
            backgroundColor: ['#dc2626', '#059669'],
            borderWidth: 0,
          },
        ],
      },
    }));
  }

  trackByFn(index: number, item: any) {
    return item.label;
  }

  onReserve() {
    localStorage.setItem('selectedParkingData', JSON.stringify(this.data));
    this.router.navigate(['/prices']);
  }

  getAvailablePlaces(): string {
    const totalAvailable = this.data.capacity.reduce(
      (sum, cap) => sum + cap.availablePlaces,
      0
    );
    const totalPlaces = this.data.capacity.reduce(
      (sum, cap) => sum + cap.totalPlaces,
      0
    );
    return `${totalAvailable} sur ${totalPlaces}`;
  }

  getEstimatedTime(): number {
    return 15; // Placeholder
  }

  getDistance(): string {
    return '2.5'; // Placeholder
  }

  // onReserve() {
  //   localStorage.setItem('selectedParkingData', JSON.stringify(this.data));
  //   this.router.navigate(['/prices']);
  // }
}
