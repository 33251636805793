import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Chart, ChartData, ChartType, registerables } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

Chart.register(...registerables); // Register necessary chart components

@Component({
  selector: 'app-dashboard-number-of-opening-by-parking',
  standalone: true,
  imports: [BaseChartDirective],
  templateUrl: './dashboard-number-of-opening-by-parking.component.html',
  styleUrl: './dashboard-number-of-opening-by-parking.component.css',
})
export class DashboardNumberOfOpeningByParkingComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective<'bar'> | undefined;
  @Input() openingByParkingsData!: any;
  ngOnInit() {}

  public barChartOptions = {
    responsive: true,
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        min: 10,
      },
    },
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };

  public barChartLabels = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
  ];
  public barChartType = 'bar';
  public barChartLegend = true;

  public barChartData: ChartData<'bar'> = {
    labels: [
      'Consigne gare',
      'Consigne Mairie',
      'Consigne centre 1',
      'Consigne centre 2',
    ],
    datasets: [
      { data: [65, 59, 80, 81, 56, 55, 40], label: 'Septembre' },
      { data: [65, 59, 80, 81, 56, 55, 40], label: 'Octobre' },
      { data: [28, 48, 40, 19, 86, 27, 90], label: 'Novembre' },
    ],
  };
}
