import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParkingPicture } from './parking.interface';

@Component({
  selector: 'app-image-carousel',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="carousel-container">
      <div
        class="carousel-slide"
        [style.transform]="'translateX(' + -currentIndex * 100 + '%)'"
      >
        @for (image of images; track image.fileId) {
          <div class="carousel-item">
            <img
              [src]="image.url"
              [alt]="image.originalName"
              (error)="onImageError($event)"
            />
          </div>
        }
        @if (!images?.length) {
          <div class="carousel-item placeholder">
            <i class="material-icons">pedal_bike</i>
          </div>
        }
      </div>

      <div class="carousel-dots">
        @for (dot of images; track dot.fileId; let i = $index) {
          <button
            class="dot"
            [class.active]="i === currentIndex"
            (click)="setCurrentSlide(i)"
          ></button>
        }
      </div>

      <button class="carousel-control prev" (click)="prev()">
        <i class="material-icons">chevron_left</i>
      </button>
      <button class="carousel-control next" (click)="next()">
        <i class="material-icons">chevron_right</i>
      </button>
    </div>
  `,
  styles: [
    `
      .carousel-container {
        position: relative;
        width: 100%;
        height: 200px;
        overflow: hidden;
        border-radius: 10px;
      }

      .carousel-slide {
        display: flex;
        transition: transform 0.3s ease-in-out;
        height: 100%;
      }

      .carousel-item {
        min-width: 100%;
        height: 100%;
      }

      .carousel-item img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .carousel-item.placeholder {
        background-color: #f0f0f0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .carousel-item.placeholder i {
        font-size: 48px;
        color: #999;
      }

      .carousel-control {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: rgba(255, 255, 255, 0.8);
        border: none;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: background-color 0.2s;
      }

      .carousel-control:hover {
        background: rgba(255, 255, 255, 0.9);
      }

      .prev {
        left: 10px;
      }
      .next {
        right: 10px;
      }

      .carousel-dots {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        gap: 8px;
      }

      .dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.5);
        border: none;
        cursor: pointer;
        padding: 0;
      }

      .dot.active {
        background: white;
      }
    `,
  ],
})
export class ImageCarouselComponent implements OnInit {
  @Input() images: ParkingPicture[] = [];
  currentIndex = 0;

  ngOnInit() {
    if (!this.images?.length) {
      this.images = []; // Ensure we have an empty array for the placeholder
    }
  }

  prev() {
    this.currentIndex =
      this.currentIndex > 0 ? this.currentIndex - 1 : this.images.length - 1;
  }

  next() {
    this.currentIndex =
      this.currentIndex < this.images.length - 1 ? this.currentIndex + 1 : 0;
  }

  setCurrentSlide(index: number) {
    this.currentIndex = index;
  }

  onImageError(event: Event) {
    const img = event.target as HTMLImageElement;
    img.src = 'assets/placeholder.png'; // Add a placeholder image
  }
}
