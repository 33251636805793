import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
// import { CanActivate, Router } from '@angular/router';
import { LoginService } from '../login/login.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private loginService: LoginService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!this.loginService.isConnected()) {
      this.router.navigate(['/login']);
      return false;
    }

    const requiredRole = route.data['requiredRole'] as number;
    if (requiredRole) {
      let hasPermission = false;

      this.loginService.profile$.subscribe((profile) => {
        if (profile && profile.roles) {
          hasPermission = (profile.roles & requiredRole) !== 0;
        }
      });

      if (!hasPermission) {
        this.router.navigate(['/parking']);
        return false;
      }
    }

    return true;
  }
}
