import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Chart, ChartData, ChartType, registerables } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

Chart.register(...registerables); // Register necessary chart components
@Component({
  selector: 'app-dashboard-filling-rate',
  standalone: true,
  imports: [BaseChartDirective],
  templateUrl: './dashboard-filling-rate.component.html',
  styleUrl: './dashboard-filling-rate.component.css',
})
export class DashboardFillingRateComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective<'pie'> | undefined;
  @Input() fillingRateData!: any;
  public fillingRatePctValue!: string;

  public chartOptions = {
    responsive: true,
    maintainAspectRatio: true,

    animation: {
      onComplete: (context) => {},
    },
  };

  // public barChartType = 'doughnut';
  public barChartLegend = true;

  // public barChartData: ChartData<'doughnut'> = {
  //   labels: ['OK', 'KO'],
  //   datasets: [{ data: [105, 23], label: 'Series A' }],
  // };

  ngOnInit() {
    // Optional: If you need to extend or add plugin logic, you can also do it here
    console.log();
    this.fillingRatePctValue = Math.round(
      (this.fillingRateData.occupied * 100) / this.fillingRateData.total
    ).toFixed(1);
  }
  public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartData = {
    datasets: [
      {
        labels: ['OK', 'KO'],
        data: [105, 23],
        backgroundColor: ['#F7464A', '#E2EAE9'],
      },
    ],
  };

  // drawCustomText(chart: Chart) {
  //   const ctx = chart.ctx;
  //   console.log(ctx);
  //   const width = chart.width;
  //   const height = chart.height;
  //   const fontSize = (height / 114).toFixed(2);
  //   ctx.font = `${fontSize}em Poppins`;
  //   ctx.textBaseline = 'middle';
  //   const text = '82%';
  //   const textX = Math.round((width - ctx.measureText(text).width) / 2);
  //   const textY = height / 2;
  //   ctx.fillText(text, textX, textY);
  // }
}
