<button
  class="margin-bottom-20"
  mat-raised-button
  color="primary"
  (click)="create()"
>
  Nouvelle Priorité
</button>

<app-table
  [apiUrl]="apiUrl"
  [columns]="tableColumns"
  [pageSize]="pageSize"
  [actions]="actions"
  [reorderable]="false"
  (actionTriggered)="onActionTriggered($event)"
  [enablePagination]="true"
  [enableActions]="true"
  [reloadTrigger]="reloadEvent"
>
</app-table>
