<div class="padding-40">
  <div class="title-container">
    <div class="flex flex-row justify-content-space-between">
      <div class="flex flex-column">
        <h2 class="title-page">Gestion des incidents</h2>
        <p class="sub-title">Listing des incidents</p>
        <button
          class="margin-bottom-20"
          mat-raised-button
          color="primary"
          (click)="createTicket()"
        >
          Nouvel incident
        </button>
      </div>
      <div class="flex chart-wrapper">
        <app-ticket-chart-incidents
          style="width: 100%"
        ></app-ticket-chart-incidents>
      </div>
    </div>
  </div>

  <div
    class="flex flex-row margin-x-between-30"
    [ngClass]="{ 'full-width': !selectFirstAutomatically || !hasData }"
  >
    <div
      class="flex"
      [ngClass]="{ 'full-width': !selectFirstAutomatically || !hasData }"
    >
      <div class="flex flex-column">
        <div>
          <app-table
            [apiUrl]="apiUrl"
            [columns]="tableColumns"
            [pageSize]="pageSize"
            [actions]="actions"
            [reorderable]="false"
            (actionTriggered)="onActionTriggered($event)"
            (tableItemsFetched)="onTableItemsFetched($event)"
            [enablePagination]="true"
            [enableActions]="true"
            [reloadTrigger]="reloadEvent"
          >
          </app-table>
        </div>
      </div>
    </div>

    <div
      class="flex flex-column"
      *ngIf="(selectFirstAutomatically && hasData) || selectedItem"
    >
      <div>
        <app-ticket-management-detail
          [data]="selectedItem"
          (refreshRequest)="onRefreshRequest()"
        >
        </app-ticket-management-detail>
      </div>
      <div class="flex flex-column">
        <!-- <app-ticket-management-create></app-ticket-management-create> -->
      </div>
    </div>
  </div>
</div>
