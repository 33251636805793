<div class="padding-20 flex flex-column">
  <h3 class="title-page">
    {{ isEdit ? "Mise à jour de categorie" : "Créer une nouvelle categorie" }}
  </h3>
  <h4 class="sub-title">Information des categories pour les incident</h4>
  <div class="padding-20 flex flex-column">
    <form class="flex flex-column" [formGroup]="form">
      <mat-form-field>
        <mat-label>Label</mat-label>
        <input
          matInput
          class="form-control"
          id="label"
          formControlName="label"
          placeholder=""
        />
      </mat-form-field>
      <mat-slide-toggle formControlName="enabled" class="margin-top-10">
        {{ form.get("enabled")?.value ? "Activé" : "Désactivé" }}
      </mat-slide-toggle>

      <button
        mat-raised-button
        color="primary"
        class="btn btn-primary"
        type="submit"
        [disabled]="!form.valid"
        (click)="isEdit ? update() : create()"
      >
        {{ isEdit ? "Mettre à jour" : "Créer" }}
      </button>
    </form>
  </div>
</div>
