<div class="padding-40">
  <div>
    <h2 class="title-page">Gestion des paramètres</h2>
    <p class="sub-title">Parametres globaux de l'application</p>
  </div>

  <div class="flex flex-row margin-x-between-30">
    <div class="flex flex-column">
      <div>
        <p class="table-title">Status des intervention</p>
        <app-ticket-status></app-ticket-status>
      </div>
    </div>

    <div class="flex flex-column">
      <div>
        <p class="table-title">Priorité des interventions</p>
        <app-ticket-priority></app-ticket-priority>
      </div>
    </div>
  </div>

  <div class="flex flex-row margin-x-between-30">
    <div class="flex flex-column">
      <div>
        <p class="table-title">Categories d'intervention</p>
        <app-ticket-category></app-ticket-category>
      </div>
    </div>

    <div class="flex flex-column">
      <div>
        <p class="table-title">Susbcription plans</p>
        <app-subscription-plan></app-subscription-plan>
      </div>
    </div>
  </div>

  <div class="flex flex-row margin-x-between-30">
    <div class="flex flex-column">
      <div>
        <p class="table-title">Type de velo</p>
        <app-parking-bike-type></app-parking-bike-type>
      </div>
    </div>

    <div class="flex flex-column">
      <div>
        <p class="table-title">Type d'access</p>
        <app-parking-access-type></app-parking-access-type>
      </div>
    </div>
  </div>

  <div class="flex flex-row margin-x-between-30">
    <div class="flex flex-column">
      <div>
        <p class="table-title">Domains (city groups)</p>
        <app-city-groups-management></app-city-groups-management>
      </div>
    </div>

    <!-- <div class="flex flex-column">
      <div>
        <p class="table-title">Type d'access</p>
        <app-parking-access-type></app-parking-access-type>
      </div>
    </div> -->
  </div>
</div>
