import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ParkingManagementService } from '../../parking-management.service';

@Component({
  selector: 'app-parking-bike-type-edit',
  standalone: true,
  imports: [
    // ParkingManagementService,
    MatSlideToggleModule,
    ReactiveFormsModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './parking-bike-type-edit.component.html',
  styleUrl: './parking-bike-type-edit.component.css',
})
export class ParkingBikeTypeEditComponent {
  isEdit = false; // Default to creation mode
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ParkingBikeTypeEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private parkingManagementService: ParkingManagementService
  ) {
    this.isEdit = !!(data && data.id);

    // Initialize form
    this.form = new FormGroup({
      label: new FormControl(data?.label || '', [Validators.required]),
      description: new FormControl(data?.description || '', [
        Validators.required,
      ]),
      enabled: new FormControl(data?.enabled ?? true, [Validators.required]),
    });
  }

  update() {
    let data = { ...this.form.value };

    this.parkingManagementService
      .updateParkingBikeType(data, this.data.id)
      .subscribe({
        next: () => {
          console.log('Update successful:', data);
          this.dialogRef.close(data); // Close the modal and pass back updated data
        },
        error: (err) => console.log(err),
      });
  }
  create(): void {
    if (this.form.valid) {
      const createData = this.form.value;
      this.parkingManagementService
        .createParkingBikeType(createData)
        .subscribe({
          next: (createdData) => {
            console.log('Creation successful:', createdData);
            this.dialogRef.close(createdData); // Pass back the created data
          },
          error: (err) => console.error('Error creating:', err),
        });
    }
  }
}
