import { Component, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserManagementService } from './user-management.service';
import { UserManagementEditComponent } from './user-management-edit/user-management-edit.component';
import { UserManagementDetailComponent } from './user-management-detail/user-management-detail.component';
import { NgClass, NgIf } from '@angular/common';
import { TableComponent } from '../table/table.component';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-management',
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    MatChipsModule,
    TableComponent,
    UserManagementDetailComponent,
    MatButtonModule,
  ],
  templateUrl: './user-management.component.html',
  styleUrl: './user-management.component.css',
})
export class UserManagementComponent {
  reloadEvent = new EventEmitter<void>();
  selectedUser: any = null;
  selectFirstAutomatically = false;
  hasData = false;
  apiUrl = environment.apiUrl + '/users?table_format=true';
  pageSize = 25;

  tableColumns = [
    { columnDef: 'email', header: 'Email' },
    { columnDef: 'firstName', header: 'Prénom' },
    { columnDef: 'lastName', header: 'Nom' },
    { columnDef: 'isActive', header: 'Status' },
    {
      columnDef: 'roles',
      header: 'Roles',
      component: 'app-table-widget-role',
    },
  ];

  actions = [
    // { label: 'View Details', call: 'displayDetails' },
    { label: 'Editer', call: 'editUser' },
    // { label: 'Toggle Status', call: 'toggleStatus' },
  ];

  constructor(
    private dialog: MatDialog,
    private userService: UserManagementService
  ) {}

  displayDetails(data: any) {
    this.selectedUser = data;
  }

  onRefreshRequest() {
    this.reloadEvent.emit();
    if (this.selectedUser) {
      this.loadUserDetails(this.selectedUser.id);
    }
  }

  onTableItemsFetched(result: { items: any[]; totalItems: number }) {
    this.hasData = result.items.length > 0;
    if (this.selectFirstAutomatically && this.hasData && !this.selectedUser) {
      this.displayDetails(result.items[0]);
    }
  }

  onActionTriggered(event: { action: string; data: any }) {
    if (event.action && typeof this[event.action] === 'function') {
      this[event.action](event.data);
    }
  }

  createUser(): void {
    const dialogRef = this.dialog.open(UserManagementEditComponent, {
      width: '800px',
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.reloadEvent.emit();
        this.selectedUser = null;
      }
    });
  }

  editUser(user: any): void {
    console.log(user);
    const dialogRef = this.dialog.open(UserManagementEditComponent, {
      width: '800px',
      data: { ...user },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.reloadEvent.emit();
        this.loadUserDetails(user.id);
      }
    });
  }

  toggleStatus(user: any): void {
    this.userService.toggleUserStatus(user.id, !user.isActive).subscribe(() => {
      this.reloadEvent.emit();
      if (this.selectedUser?.id === user.id) {
        this.loadUserDetails(user.id);
      }
    });
  }

  private loadUserDetails(userId: string): void {
    this.userService.getUserById(userId).subscribe({
      next: (user) => {
        this.selectedUser = user;
      },
      error: (error) => {
        console.error('Error loading user details:', error);
      },
    });
  }
}
