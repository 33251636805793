import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-subscription-validated',
  standalone: true,
  imports: [],
  templateUrl: './subscription-validated.component.html',
  styleUrl: './subscription-validated.component.css',
})
export class SubscriptionValidatedComponent {
  email = this.router.getCurrentNavigation().extras.state.email;
  // email = 'kevin.firaguay@gmail.com';
  constructor(private router: Router) {
    console.log(this.router.getCurrentNavigation().extras.state.email); // should log out 'bar'
  }
}
