import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SortDirection } from '@angular/material/sort';
import {
  IBikeType,
  IParkingAccessType,
  IParkingData,
  IParkingStatus,
} from '../common/management.interfaces';

@Injectable({
  providedIn: 'root',
})
export class CityGroupManagementService {
  // private _parkingStatus: IParkingStatus[] = [];
  // private _parkingBikeType: IBikeType[] = [];
  // private _parkingAccessType: IParkingAccessType[] = [];

  private apiUrl = environment.apiUrl;
  private apiRouteCityGroups = 'cityGroups';
  // private apiRouteParkingAccessType = 'parking/access';
  private headers: HttpHeaders;
  pageSize = 100;
  public sortDirection: 'asc' | 'desc' = 'asc';
  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  }

  // parkings(
  //   sort: string,
  //   order: SortDirection,
  //   page: number
  // ): Observable<{ items: []; total_count: number }> {
  //   return this.http.get<{ items: []; total_count: number }>(
  //     this.apiUrl +
  //       `/parking?page=${page + 1}&size=${this.pageSize}&sortField=${sort}&order=${order}`,
  //     { headers: this.headers }
  //   );
  // }

  // parking(id: string): Observable<IParkingData> {
  //   return this.http.get<IParkingData>(this.apiUrl + `/parking/id/${id}`, {
  //     headers: this.headers,
  //   });
  // }

  createCityGroup(data: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .post<{
        bikeType: any;
      }>(this.apiUrl + '/' + this.apiRouteCityGroups, data, {
        headers,
      })
      .pipe(tap((response) => {}));
  }

  updateCityGroup(
    id: string,
    data: Partial<IParkingData>
  ): Observable<IParkingData> {
    return this.http.put<any>(
      this.apiUrl + `/${this.apiRouteCityGroups}/${id}`,
      data
    );
  }

  // parkingStatus(): Observable<IParkingStatus[]> {
  //   if (this._parkingStatus.length == 0) {
  //     return this.http
  //       .get<IParkingStatus[]>(this.apiUrl + `/parking/status`, {
  //         headers: this.headers,
  //       })
  //       .pipe(
  //         map((status) => {
  //           this._parkingStatus = status;
  //           return status;
  //         })
  //       );
  //   } else {
  //     return of(this._parkingStatus);
  //   }
  // }

  // parkingBikeType(): Observable<IBikeType[]> {
  //   if (this._parkingBikeType.length == 0) {
  //     return this.http
  //       .get<IBikeType[]>(this.apiUrl + `/parking/biketype`, {
  //         headers: this.headers,
  //       })
  //       .pipe(
  //         map((types) => {
  //           this._parkingBikeType = types;
  //           return types;
  //         })
  //       );
  //   } else {
  //     return of(this._parkingBikeType);
  //   }
  // }

  // parkingAccessType(): Observable<IParkingAccessType[]> {
  //   if (this._parkingBikeType.length == 0) {
  //     return this.http
  //       .get<IParkingAccessType[]>(this.apiUrl + `/parking/access`, {
  //         headers: this.headers,
  //       })
  //       .pipe(
  //         map((types) => {
  //           this._parkingAccessType = types;
  //           return types;
  //         })
  //       );
  //   } else {
  //     return of(this._parkingAccessType);
  //   }
  // }

  // parkingStatusByIs(id: string): IParkingStatus {
  //   return this._parkingStatus.find((status) => status.id === id);
  // }

  // parkingAccessTypeById(id: string): IParkingAccessType {
  //   return this._parkingAccessType.find((type) => type.id === id);
  // }

  // parkingByTypeById(id: string): IBikeType {
  //   return this._parkingBikeType.find((type) => type.id === id);
  // }

  // updateParkingBikeType(data: any): Observable<any> {
  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   return this.http
  //     .put<{
  //       bikeType: any;
  //     }>(
  //       this.apiUrl + '/' + this.apiRouteParkingBikeType + '/' + data.id,
  //       data,
  //       {
  //         headers,
  //       }
  //     )
  //     .pipe(tap((response) => {}));
  // }

  // createParkingBikeType(data: any): Observable<any> {
  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   return this.http
  //     .post<{
  //       bikeType: any;
  //     }>(this.apiUrl + '/' + this.apiRouteParkingBikeType, data, {
  //       headers,
  //     })
  //     .pipe(tap((response) => {}));
  // }

  // updateParkingAccessType(data: any): Observable<any> {
  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   return this.http
  //     .put<{
  //       accessType: any;
  //     }>(
  //       this.apiUrl + '/' + this.apiRouteParkingAccessType + '/' + data.id,
  //       data,
  //       {
  //         headers,
  //       }
  //     )
  //     .pipe(tap((response) => {}));
  // }

  // createParkingAccessType(data: any): Observable<any> {
  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   return this.http
  //     .post<{
  //       accessType: any;
  //     }>(this.apiUrl + '/' + this.apiRouteParkingAccessType, data, {
  //       headers,
  //     })
  //     .pipe(tap((response) => {}));
  // }
}
