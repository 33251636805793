import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'map',
  standalone: true,
})
export class MapPipe implements PipeTransform {
  transform(array: any[], key: string): any[] {
    if (!array) return [];
    return array.map((item) => item[key]);
  }
}

@Pipe({
  name: 'join',
  standalone: true,
})
export class JoinPipe implements PipeTransform {
  transform(array: any[], separator: string = ', '): string {
    if (!array) return '';
    return array.join(separator);
  }
}
