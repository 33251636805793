import {
  Component,
  Input,
  OnInit,
  ComponentFactoryResolver,
  ViewChild,
} from '@angular/core';
import { DynamicHostDirective } from '../dynamic-host-directive'; // Adjust the path if necessary

@Component({
  selector: 'app-table-dynamic-column',
  standalone: true,
  imports: [DynamicHostDirective], // Import the directive here
  template: `<ng-template dynamicHost></ng-template>`,
  styleUrls: ['./table-dynamic-column.component.css'],
})
export class TableDynamicColumnComponent implements OnInit {
  @Input() component: any;
  @Input() data: any;

  @ViewChild(DynamicHostDirective, { static: true })
  dynamicHost!: DynamicHostDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit() {
    if (this.component) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(this.component);
      if (!this.dynamicHost) {
        console.error('DynamicHostDirective is not available');
        return;
      }
      const viewContainerRef = this.dynamicHost.viewContainerRef;
      viewContainerRef.clear();
      const componentRef = viewContainerRef.createComponent(componentFactory);
      if (componentRef.instance) {
        (componentRef.instance as any).data = this.data;
      }
    }
  }
}
