import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { LoginService } from './login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '../local-storage/local-storage.service';

import { MatFormFieldModule } from '@angular/material/form-field';
import { FormArray, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { trigger, transition, style, animate } from '@angular/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  // loginForm: FormGroup;
  hidePassword = true;
  isLoading = false;

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    if (this.router.getCurrentNavigation()?.extras?.state?.name) {
      this.previous = this.router.getCurrentNavigation()?.extras?.state?.name;
    }

    // check if we are already logged
    if (this.loginService.isConnected()) {
      console.log('already connected');
      this.router.navigate(['/parking']);
    }
  }
  previous = null;
  loginForm = new FormGroup({
    email: new FormControl('kevin.firaguay@gmail.com', [
      Validators.required,
      Validators.email,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
    ]),
    password: new FormControl('testtest'),
  });

  login() {
    this.loginService.login(
      this.loginForm.value.email,
      this.loginForm.value.password
    );

    this.loginService
      .login(this.loginForm.value.email, this.loginForm.value.password)
      .subscribe({
        next: () => this.router.navigate(['/parking']),
        error: (err) =>
          alert('La connexion à échoué, veuillez verifier vos identifiants'),
      });
  }

  goToSubscribePage() {
    this.router.navigate(['/subscribe']);
  }
}
