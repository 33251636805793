<div class="container">
  <h2 class="title-page">Vos commandes</h2>
  <p class="sub-title">
    Consultez vos pass et tickets en cours de validité et votre historique
  </p>
  <div class="sub-container">
    @for (order of orders; track order) {
      <div class="child">
        <mat-card appearance="outlined">
          <mat-card-header>
            <mat-card-title>Ticket 24h - 4€</mat-card-title>
            <mat-card-subtitle>Commandé le 07/09/2024 </mat-card-subtitle>
          </mat-card-header>
          <mat-card-actions>
            <button mat-button>Detail de la commande</button>
          </mat-card-actions>
        </mat-card>
      </div>
    }
  </div>
</div>
