import {
  Component,
  OnInit,
  Inject,
  Injectable,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';

import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_NATIVE_DATE_FORMATS,
  NativeDateAdapter,
  MatNativeDateModule,
} from '@angular/material/core';

import { TicketManagementService } from '../ticket-management.service';
import { firstValueFrom } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { MatStepperModule } from '@angular/material/stepper';
import {
  MatTimepickerModule,
  MatTimepickerIntl,
  provideNativeDateTimeAdapter,
} from '@dhutaryan/ngx-mat-timepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Injectable()
export class MyIntl extends MatTimepickerIntl {
  okButton = 'Valider';
}

@Component({
  selector: 'app-ticket-management-edit',
  templateUrl: './ticket-management-edit.component.html',
  styleUrls: ['./ticket-management-edit.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatTimepickerModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    { provide: MatTimepickerIntl, useClass: MyIntl },
    { provide: DateAdapter, useClass: NativeDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MAT_NATIVE_DATE_FORMATS },
    provideNativeDateTimeAdapter(), // Added this line
  ],
})
export class TicketManagementEditComponent implements OnInit {
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('documentInput') documentInput!: ElementRef;

  isEdit = false;
  isLinear = true;
  ticketForm!: FormGroup;

  categories: any[] = [];
  statuses: any[] = [];
  priorities: any[] = [];
  parkings: any[] = [];

  apiUrl = environment.apiUrl;

  constructor(
    private fb: FormBuilder,
    private ticketService: TicketManagementService,
    public dialogRef: MatDialogRef<TicketManagementEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer
  ) {
    this.isEdit = data && data.id != null;
    this.initializeForm();
  }

  async ngOnInit() {
    await this.loadCategories();
    await this.loadStatuses();
    await this.loadPriorities();
    await this.loadParkings();

    if (this.data?.id) {
      await this.loadTicketData();
    }
  }

  private initializeForm() {
    this.ticketForm = this.fb.group({
      stepsGroup: this.fb.group({
        dateTimeStep: this.fb.group({
          date: [new Date(), Validators.required],
          time: [new Date(), Validators.required],
        }),
        titleDescriptionStep: this.fb.group({
          title: ['', Validators.required],
          description: ['', Validators.required],
        }),
        parkingStep: this.fb.group({
          parking: ['', Validators.required],
        }),
        statusPriorityTypeStep: this.fb.group({
          status: ['', Validators.required],
          priority: ['', Validators.required],
          categories: [[], Validators.required],
        }),
        filesStep: this.fb.group({}),
      }),
    });
  }

  private async loadCategories() {
    try {
      const response = await firstValueFrom(this.ticketService.getCategories());
      this.categories = response.items;
    } catch (error) {
      console.error('Error loading categories:', error);
    }
  }

  private async loadStatuses() {
    try {
      const response = await firstValueFrom(this.ticketService.getStatus());
      this.statuses = response.items;
    } catch (error) {
      console.error('Error loading statuses:', error);
    }
  }

  private async loadPriorities() {
    try {
      const response = await firstValueFrom(this.ticketService.getPriorities());
      this.priorities = response.items;
    } catch (error) {
      console.error('Error loading priorities:', error);
    }
  }

  private async loadParkings() {
    try {
      const response = await firstValueFrom(this.ticketService.getParkings());
      this.parkings = response.map((parking: any) => ({
        id: parking.id,
        name: `${parking.name} - ${parking.city}`,
        city: parking.city,
      }));
    } catch (error) {
      console.error('Error loading parkings:', error);
    }
  }

  private async loadTicketData() {
    try {
      const ticket = await firstValueFrom(
        this.ticketService.getTicketById(this.data.id)
      );

      // Patch date and time
      const incidentDate = new Date(ticket.incidentDate);

      this.ticketForm.patchValue({
        stepsGroup: {
          dateTimeStep: {
            date: incidentDate,
            time: incidentDate,
          },
          titleDescriptionStep: {
            title: ticket.title,
            description: ticket.description,
          },
          parkingStep: {
            parking: ticket.parking?._id,
          },
          statusPriorityTypeStep: {
            status: ticket.status._id,
            priority: ticket.priority._id,
            categories: ticket.categories.map((cat: any) => cat._id),
          },
        },
      });
    } catch (error) {
      console.error('Error loading ticket data:', error);
    }
  }

  saveTicket() {
    if (this.ticketForm.valid) {
      const ticketData = this.formatTicketPayload();

      const request = this.isEdit
        ? this.ticketService.updateTicket(this.data.id, ticketData)
        : this.ticketService.createTicket(ticketData);

      request.subscribe({
        next: (response) => {
          this.dialogRef.close(response);
        },
        error: (error) => {
          console.error('Error saving ticket:', error);
        },
      });
    } else {
      // Mark all fields as touched to trigger validation messages
      this.ticketForm.markAllAsTouched();
    }
  }

  private formatTicketPayload() {
    const formValue = this.ticketForm.value.stepsGroup;
    const date = new Date(formValue.dateTimeStep.date);
    const time = new Date(formValue.dateTimeStep.time);

    const incidentDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.getHours(),
      time.getMinutes()
    ).getTime();

    return {
      title: formValue.titleDescriptionStep.title,
      description: formValue.titleDescriptionStep.description,
      categories: formValue.statusPriorityTypeStep.categories,
      priority: formValue.statusPriorityTypeStep.priority,
      status: formValue.statusPriorityTypeStep.status,
      parking: formValue.parkingStep.parking,
      incidentDate: incidentDate,
    };
  }

  // File handling methods
  async onFileSelected(event: Event, type: 'file' | 'picture') {
    const element = event.target as HTMLInputElement;
    const fileList: FileList | null = element.files;

    if (fileList) {
      const files = Array.from(fileList);
      const formData = new FormData();

      files.forEach((file) => {
        formData.append('files', file);
      });

      try {
        const response = await firstValueFrom(
          this.ticketService.uploadFiles(formData)
        );
        const fileIds = response.map((file: any) => file.fileId);

        // Update ticket with new file IDs
        const updateData: any = {};
        if (type === 'file') {
          updateData.files = fileIds;
        } else {
          updateData.pictures = fileIds;
        }

        const updatedTicket = await firstValueFrom(
          this.ticketService.updateTicket(this.data.id, updateData)
        );

        this.data = updatedTicket;
      } catch (error) {
        console.error('Error uploading files:', error);
      }
    }
  }

  async deleteFile(fileId: string, type: 'file' | 'picture') {
    try {
      await firstValueFrom(
        this.ticketService.deleteFile(this.data.id, fileId, type)
      );
      const updatedTicket = await firstValueFrom(
        this.ticketService.getTicketById(this.data.id)
      );
      this.data = updatedTicket;
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  }

  openFile(file: any, type: 'picture' | 'file') {
    const url = type === 'picture' ? this.getFileUrl(file.fileId) : file.url;
    window.open(url as string, '_blank', 'width=1024,height=768');
  }

  getFileUrl(fileId: string): SafeUrl {
    const url = `${this.apiUrl}/upload/${fileId}`;
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  trackByParking(index: number, item: any) {
    return item.id;
  }

  trackByStatus(index: number, item: any) {
    return item.id;
  }

  trackByPriority(index: number, item: any) {
    return item.id;
  }

  trackByCategory(index: number, item: any) {
    return item.id;
  }

  trackByFileId(index: number, item: any) {
    return item.fileId;
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
