import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { CityGroupManagementService } from '../city-group-management.service';
import { combineLatest, map, Observable, startWith } from 'rxjs';
import { ParkingManagementService } from 'src/app/parking-management/parking-management.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
// import { ParkingManagementService } from '../../parking-management.service';
// import { TicketManagementService } from '../../ticket-management.service';
@Component({
  selector: 'app-city-groups-management-edit',
  standalone: true,
  imports: [
    MatCheckboxModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './city-groups-management-edit.component.html',
  styleUrl: './city-groups-management-edit.component.css',
})
export class CityGroupsManagementEditComponent {
  isEdit = false;
  form: FormGroup;
  availableParkings$: Observable<any[]>; // For available parkings
  filteredParkings$: Observable<any[]>;
  searchControl = new FormControl('');

  constructor(
    public dialogRef: MatDialogRef<CityGroupsManagementEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cityGroupsManagementService: CityGroupManagementService,
    private parkingService: ParkingManagementService // Add parking service
  ) {
    this.isEdit = !!(data && data.id);
    this.initializeForm();
    this.loadAvailableParkings();
    this.setupParkingsList();
  }

  private initializeForm() {
    this.form = new FormGroup({
      name: new FormControl(this.data?.name || '', [Validators.required]),
      city: new FormControl(this.data?.city || '', [Validators.required]),
      parkings: new FormControl(this.data?.parkings || []),
      enabled: new FormControl(this.data?.enabled ?? true),
    });
  }

  private setupParkingsList() {
    // Get available parkings
    this.availableParkings$ = this.parkingService.getAvailableParkings();

    // Setup filtering
    this.filteredParkings$ = combineLatest([
      this.availableParkings$,
      this.searchControl.valueChanges.pipe(startWith('')),
    ]).pipe(
      map(([parkings, searchTerm]) => {
        if (!searchTerm) return parkings;
        searchTerm = searchTerm.toLowerCase();
        return parkings.filter(
          (parking) =>
            parking.name.toLowerCase().includes(searchTerm) ||
            parking.description?.toLowerCase().includes(searchTerm)
        );
      })
    );
  }

  private loadAvailableParkings() {
    // Get parkings that are not in any city group
    this.availableParkings$ = this.parkingService.getAvailableParkings();
  }

  // Method to check if a parking is selected
  isParkingSelected(parkingId: string): boolean {
    const selectedParkings = this.form.get('parkings').value;
    return selectedParkings.includes(parkingId);
  }

  // Method to toggle parking selection
  toggleParking(parkingId: string) {
    const parkings = this.form.get('parkings').value;
    const index = parkings.indexOf(parkingId);

    if (index === -1) {
      parkings.push(parkingId);
    } else {
      parkings.splice(index, 1);
    }

    this.form.patchValue({ parkings });
  }

  update() {
    let data = { ...this.form.value };

    this.cityGroupsManagementService
      .updateCityGroup(this.data.id, data)
      .subscribe({
        next: () => {
          console.log('Update successful:', data);
          this.dialogRef.close(data); // Close the modal and pass back updated data
        },
        error: (err) => console.log(err),
      });
  }
  create(): void {
    if (this.form.valid) {
      const createData = this.form.value;
      this.cityGroupsManagementService.createCityGroup(createData).subscribe({
        next: (createdData) => {
          console.log('Creation successful:', createdData);
          this.dialogRef.close(createdData); // Pass back the created data
        },
        error: (err) => console.error('Error creating:', err),
      });
    }
  }
}
// export class CityGroupsManagementEditComponent {
//   isEdit = false; // Default to creation mode
//   form: FormGroup;

//   constructor(
//     public dialogRef: MatDialogRef<CityGroupsManagementEditComponent>,
//     @Inject(MAT_DIALOG_DATA) public data: any,
//     private cityGroupsManagementService: CityGroupManagementService
//   ) {
//     this.isEdit = !!(data && data.id);

//     console.log(data);

//     // Initialize form
//     this.form = new FormGroup({
//       name: new FormControl(data?.name || '', [Validators.required]),
//       description: new FormControl(data?.description || '', [
//         Validators.required,
//       ]),
//       enabled: new FormControl(data?.enabled ?? true, [Validators.required]),
//     });
//   }

//   update() {
//     let data = { ...this.form.value, id: this.data.id };

//     this.cityGroupsManagementService
//       .updateCityGroup(this.data.id, data)
//       .subscribe({
//         next: () => {
//           console.log('Update successful:', data);
//           this.dialogRef.close(data); // Close the modal and pass back updated data
//         },
//         error: (err) => console.log(err),
//       });
//   }
//   create(): void {
//     if (this.form.valid) {
//       const createData = this.form.value;
//       this.cityGroupsManagementService.createCityGroup(createData).subscribe({
//         next: (createdData) => {
//           console.log('Creation successful:', createdData);
//           this.dialogRef.close(createdData); // Pass back the created data
//         },
//         error: (err) => console.error('Error creating:', err),
//       });
//     }
//   }
// }
