<div class="container">
  <h1 class="title-page">Angular Stripe Payment</h1>

  <div class="">
    <input
      type="number"
      class="form-control"
      id="floatingAmount"
      placeholder="Amount"
      [(ngModel)]="stripeAmount"
      required
    />
    <label for="floatingAmount">Amount</label>
  </div>

  <button class="btn btn-primary w-100 py-2" type="submit" (click)="checkout()">
    Pay Now
    @if (isLoading) {
      <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
    }
  </button>
</div>
