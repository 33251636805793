import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private apiUrl = environment.apiUrl;

  private profileSubject = new BehaviorSubject<any>(null);
  profile$ = this.profileSubject.asObservable();

  constructor(private http: HttpClient) {}

  isConnected(): boolean {
    return !!localStorage.getItem('authToken');
  }

  fetchUserProfile(): Observable<any> {
    return this.http
      .get(this.apiUrl + '/users/profile')
      .pipe(tap((profile) => this.profileSubject.next(profile)));
  }

  login(email: string, password: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .post<{
        token: string;
      }>(this.apiUrl + '/auth/login', { email, password }, { headers })
      .pipe(
        tap((response) => {
          if (response.accessToken) {
            localStorage.setItem('authToken', response.accessToken);
            this.fetchUserProfile().subscribe({});
          }
        })
      );
  }
}
