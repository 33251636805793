// ticket-chart-incidents.component.ts
import { Component, OnInit, ViewChild } from '@angular/core';
import { Chart, ChartData, ChartType, registerables } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

Chart.register(...registerables);

@Component({
  selector: 'app-ticket-chart-incidents',
  standalone: true,
  imports: [BaseChartDirective],
  template: `
    <div class="chart-container">
      <canvas
        baseChart
        [data]="lineChartData"
        [options]="lineChartOptions"
        [type]="lineChartType"
      >
      </canvas>
    </div>
  `,
  styles: [
    `
      .chart-container {
        height: 200px;
        width: 100%;
        margin: 0 auto;
      }
    `,
  ],
})
export class TicketChartIncidentsComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective<'line'> | undefined;

  public lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 5,
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          boxWidth: 12,
          padding: 15,
        },
      },
    },
  };

  public lineChartType: ChartType = 'line';

  public lineChartData: ChartData<'line'> = {
    labels: Array.from({ length: 30 }, (_, i) => `Jour ${i + 1}`),
    datasets: [
      {
        data: Array.from(
          { length: 30 },
          () => Math.floor(Math.random() * 20) + 5
        ),
        label: 'Nettoyage',
        borderColor: '#4B70E2',
        tension: 0.4,
        fill: false,
      },
      {
        data: Array.from(
          { length: 30 },
          () => Math.floor(Math.random() * 15) + 3
        ),
        label: 'Vol',
        borderColor: '#FF6B6B',
        tension: 0.4,
        fill: false,
      },
      {
        data: Array.from(
          { length: 30 },
          () => Math.floor(Math.random() * 10) + 2
        ),
        label: 'Effraction',
        borderColor: '#50E3C2',
        tension: 0.4,
        fill: false,
      },
      {
        data: Array.from(
          { length: 30 },
          () => Math.floor(Math.random() * 12) + 4
        ),
        label: 'Problème Caméra',
        borderColor: '#FFD93D',
        tension: 0.4,
        fill: false,
      },
      {
        data: Array.from(
          { length: 30 },
          () => Math.floor(Math.random() * 8) + 1
        ),
        label: 'Problème Lecteur',
        borderColor: '#FF8F00',
        tension: 0.4,
        fill: false,
      },
      {
        data: Array.from(
          { length: 30 },
          () => Math.floor(Math.random() * 10) + 3
        ),
        label: 'Plainte Usager',
        borderColor: '#6C757D',
        tension: 0.4,
        fill: false,
      },
    ],
  };

  ngOnInit(): void {}
}
