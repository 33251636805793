<!-- user-management.component.html -->
<div class="padding-40">
  <div class="title-container">
    <div class="flex flex-row justify-content-space-between">
      <div class="flex flex-column">
        <h2 class="title-page">Gestion des utilisateurs</h2>
        <p class="sub-title">Liste des utilisateurs</p>
        <button
          class="margin-bottom-20"
          mat-raised-button
          color="primary"
          (click)="createUser()"
        >
          Nouvel utilisateur
        </button>
      </div>
    </div>
  </div>

  <div
    class="flex flex-row margin-x-between-30"
    [ngClass]="{ 'full-width': !selectFirstAutomatically || !hasData }"
  >
    <div
      class="flex"
      [ngClass]="{ 'full-width': !selectFirstAutomatically || !hasData }"
    >
      <div class="flex flex-column">
        <app-table
          [apiUrl]="apiUrl"
          [columns]="tableColumns"
          [pageSize]="pageSize"
          [actions]="actions"
          [reorderable]="false"
          (actionTriggered)="onActionTriggered($event)"
          (tableItemsFetched)="onTableItemsFetched($event)"
          [enablePagination]="true"
          [enableActions]="true"
          [reloadTrigger]="reloadEvent"
        >
        </app-table>
      </div>
    </div>

    <div
      class="flex flex-column"
      *ngIf="(selectFirstAutomatically && hasData) || selectedUser"
    >
      <!-- <app-user-management-detail
        [data]="selectedUser"
        (refreshRequest)="onRefreshRequest()"
      >
      </app-user-management-detail> -->
    </div>
  </div>
</div>

<!-- user-management-detail.component.html -->
