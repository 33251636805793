import {
  Component,
  EventEmitter,
  Injectable,
  Input,
  Output,
} from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { FileUploader, FileUploadModule } from 'ng2-file-upload';

import {
  MAT_TIME_LOCALE,
  MatTimepickerIntl,
  MatTimepickerModule,
  provideNativeDateTimeAdapter,
} from '@dhutaryan/ngx-mat-timepicker';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { TicketManagementEditComponent } from '../ticket-management-edit/ticket-management-edit.component';
import { MatIconModule } from '@angular/material/icon';
import { environment } from 'src/environments/environment';

interface TicketCategory {
  id: string;
  value: string;
  viewValue: string;
}

interface Priority {
  id: string;
  value: string;
  viewValue: string;
}

interface SpotNumber {
  id: string;
  value: string;
  viewValue: string;
}
const URL = environment.apiUrl + '/upload/';

@Injectable()
export class MyIntl extends MatTimepickerIntl {
  // Override default labels and messages
  okButton = 'Valider';
  upSecondLabel = 'Add a second';
  downSecondLabel = 'Subtract a second';
  upMinuteLabel = 'Add a minute';
  downMinuteLabel = 'Subtract a minute';
  upHourLabel = 'Add an hour';
  downHourLabel = 'Subtract an hour';
  // Additional customization as needed
}

uploader: FileUploader;
@Component({
  selector: 'app-ticket-management-detail',
  templateUrl: './ticket-management-detail.component.html',
  standalone: true,
  styleUrl: './ticket-management-detail.component.scss',
  imports: [
    MatButtonModule,
    MatIconModule,
    FileUploadModule,
    CommonModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatChipsModule,
    MatTimepickerModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
  ],
  providers: [
    { provide: MAT_TIME_LOCALE, useValue: 'fr-FR' },
    { provide: MatTimepickerIntl, useClass: MyIntl },
    provideNativeDateTimeAdapter(),
  ],
})
export class TicketManagementDetailComponent {
  selectedValue: string;
  selectedPriority: string;
  selectedType: string;
  selectedSpot: string;
  @Output() refreshRequest = new EventEmitter<void>();

  ticketCategories: TicketCategory[] = [
    { id: '1', value: 'Porte bloquée', viewValue: 'Porte Bloquée' },
    { id: '2', value: 'Porte fracturée', viewValue: 'Porte fracturée' },
    { id: '3', value: 'Cadenas abandonné', viewValue: 'Cadenas abandonné' },
    { id: '4', value: 'Vélo abandonné', viewValue: 'Vélo abandonné' },
    { id: '5', value: 'Lumiere à changer', viewValue: 'Lumière à changer' },
    {
      id: '6',
      value: 'Nettoyage necessaire',
      viewValue: 'Nettoyage necessaire',
    },
    { id: '7', value: 'réinstallation du lecteur', viewValue: 'Porte Bloquée' },
  ];

  spotNumbers: SpotNumber[] = [
    { id: '1', value: '1', viewValue: '1' },
    { id: '2', value: '2', viewValue: '2' },
    { id: '3', value: '3', viewValue: '3' },
    { id: '4', value: '4', viewValue: '4' },
    { id: '5', value: '5', viewValue: '5' },
    { id: '6', value: '6', viewValue: '6' },
    { id: '7', value: '7', viewValue: '7' },
    { id: '8', value: '8', viewValue: '8' },
    { id: '9', value: '9', viewValue: '9' },
    { id: '10', value: '10', viewValue: '10' },
    { id: '11', value: '11', viewValue: '11' },
    { id: '12', value: '12', viewValue: '12' },
  ];

  priorities: Priority[] = [
    { id: '1', value: '1', viewValue: 'Basse' },
    { id: '2', value: '2', viewValue: 'Normale' },
    { id: '3', value: '3', viewValue: 'Haute' },
    { id: '4', value: '4', viewValue: 'Urgent' },
  ];

  @Input() title!: string;
  @Input() data!: any;

  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;

  response: string;

  constructor(private dialog: MatDialog) {
    this.uploader = new FileUploader({
      url: URL,
      itemAlias: 'files',
      disableMultipart: false, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      // formatDataFunctionIsAsync: true,
      // formatDataFunction: async (item) => {
      //   return new Promise((resolve, reject) => {
      //     resolve({
      //       files: [
      //         {
      //           name: item._file.name,
      //           length: item._file.size,
      //           contentType: item._file.type,
      //           date: new Date(),
      //         },
      //       ],
      //     });
      //   });
      // },
    });

    this.hasBaseDropZoneOver = false;

    this.response = '';

    this.uploader.response.subscribe((res) => {
      this.response = res;
      console.log(res);
    });
  }

  saveTicket() {}

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  editTicket(ticket: any): void {
    const dialogRef = this.dialog.open(TicketManagementEditComponent, {
      width: '1200px',
      data: { ...ticket }, // Pass a copy of the ticket to avoid mutating the original
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refreshRequest.emit();
        console.log('Updated ticket:', result);
        // const index = this.tickets.findIndex((t) => t.id === ticket.id);
        // if (index !== -1) {
        //   this.tickets[index] = result; // Update the ticket in the list
        // }
      }
    });
  }
}
