<div>
  <button
    class="margin-bottom-20"
    mat-raised-button
    color="primary"
    (click)="editParkingBikeTypes()"
  >
    Nouvelle categorie
  </button>
  <app-table
    [apiUrl]="apiUrl"
    [columns]="tableColumns"
    [pageSize]="pageSize"
    [reorderable]="false"
    [actions]="actions"
    (actionTriggered)="onActionTriggered($event)"
    [enablePagination]="true"
    [enableActions]="true"
    [reloadTrigger]="reloadEvent"
    (orderChanged)="onTableOrderChanged($event)"
  >
  </app-table>
</div>
