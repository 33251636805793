/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CdkDragDrop,
  DragDropModule,
  moveItemInArray,
} from '@angular/cdk/drag-drop';

import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import {
  MatPaginator,
  MatPaginatorModule,
  PageEvent,
} from '@angular/material/paginator';
import { MatChipsModule } from '@angular/material/chips';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DatePipe } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TableDynamicColumnComponent } from './table-dynamic-column/table-dynamic-column.component';
import { TableWidgetPriorityComponent } from './table-widgets/table-widget-priority/table-widget-priority.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { PlacesComponent } from '../components/places/places.component';
import { ParkingAddressComponent } from '../components/address/address.component';
import { TableWidgetParkingComponent } from './table-widgets/table-widget-parking/table-widget-parking.component';
import { TableWidgetTicketStatusComponent } from './table-widgets/table-widget-ticket-status/table-widget-ticket-status.component';
import { TableWidgetRoleComponent } from './table-widgets/table-widget-role/table-widget-role.component';
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    DragDropModule,
    MatExpansionModule,
    TableDynamicColumnComponent,
    TableWidgetPriorityComponent,
    CommonModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    DatePipe,
    MatFormFieldModule,
    MatButtonModule,
    MatChipsModule,
    MatSlideToggleModule,
    FormsModule,
    PlacesComponent,
  ],
})
export class TableComponent implements OnInit {
  isLoading = false;
  @Input() apiUrl!: string;
  @Input() columns!: {
    columnDef: string;
    header: string;
    component?: string;
    displayed?: boolean;
    filter?: { text: string; type: 'switch' };
    useFullData?: boolean;
  }[];
  @Input() pageSize = 10;
  @Input() showColumnVisibilityToggle: boolean = false; // Add input for toggling visibility checkboxes
  @Input() actions: { label: string; call: string }[] = [];
  @Input() enablePagination: boolean = true;
  @Input() enableActions: boolean = false;
  @Input() reloadTrigger?: EventEmitter<void>;
  @Input() reorderable: boolean = true; // Toggles

  @Input() enableFirstSelection: boolean = false;

  @Output() actionTriggered = new EventEmitter<{ action: string; data: any }>();
  @Output() orderChanged = new EventEmitter<any[]>(); // Emits updated order
  @Output() tableItemsFetched = new EventEmitter<{
    items: any[];
    totalItems: number;
  }>();

  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = [];
  totalItems = 0;

  filters: { [key: string]: any } = {}; // To store filter values dynamically

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    // Initialize displayed columns based on the displayed property
    this.columns.forEach((col) => {
      if (col.displayed === undefined) {
        col.displayed = true; // Default to true if displayed is undefined
      }
    });

    this.updateDisplayedColumns();
    this.initializeFilters();
    this.fetchData(0, this.pageSize);

    // Subscribe to reloadTrigger if provided
    if (this.reloadTrigger) {
      this.reloadTrigger.subscribe(() => {
        this.reloadTable();
      });
    }
  }

  ngAfterViewInit(): void {
    if (!this.enablePagination) {
      this.dataSource.sort = this.sort;
    }
  }

  hasFiltersOrOptions(): boolean {
    const hasFilters = this.columns.some((col) => col.filter);
    const hasColumnVisibilityToggles = this.showColumnVisibilityToggle;
    return hasFilters || hasColumnVisibilityToggles;
  }

  reloadTable(): void {
    this.fetchData(
      this.paginator?.pageIndex || 0,
      this.paginator?.pageSize || this.pageSize,
      this.sort?.active,
      this.sort?.direction
    );
  }

  updateDisplayedColumns(): void {
    this.displayedColumns = this.columns
      .filter((col) => col.displayed !== false)
      .map((col) => col.columnDef);

    if (this.reorderable) {
      this.displayedColumns.unshift('drag'); // Add the drag column at the start
    }

    if (this.enableActions) {
      this.displayedColumns.push('actions');
    }
  }
  toggleColumnVisibility(columnDef: string): void {
    const column = this.columns.find((col) => col.columnDef === columnDef);
    if (column) {
      column.displayed = !column.displayed; // Toggle the displayed property
      this.updateDisplayedColumns(); // Update the displayedColumns array
    }
  }

  // Initialize filters based on column configuration
  initializeFilters(): void {
    this.columns.forEach((column) => {
      if (column.filter) {
        if (column.filter.type === 'switch') {
          this.filters[column.columnDef] = false; // Default value for switches
        }
      }
    });
  }

  // Map string identifiers to actual components
  componentMap: { [key: string]: any } = {
    'app-table-widget-priority': TableWidgetPriorityComponent,
    'app-table-widget-parking': TableWidgetParkingComponent,
    'app-table-widget-status': TableWidgetTicketStatusComponent,
    'app-table-widget-role': TableWidgetRoleComponent,

    'app-places': PlacesComponent,
    'app-parking-address': ParkingAddressComponent,
  };

  getComponent(name: string) {
    return this.componentMap[name];
  }

  onActionClick(actionName: string, rowData: any): void {
    this.actionTriggered.emit({ action: actionName, data: rowData });
  }

  onFilterChange(): void {
    this.fetchData(
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.sort?.active,
      this.sort?.direction
    );
  }

  fetchData(
    pageIndex: number,
    pageSize: number,
    sortField?: string,
    sortDirection?: string
  ): void {
    this.isLoading = true;

    let params = new HttpParams()
      .set('page', pageIndex.toString())
      .set('size', pageSize.toString());

    if (sortField) {
      params = params
        .set('sort', sortField)
        .set('direction', sortDirection || 'asc');
    }

    Object.keys(this.filters).forEach((key) => {
      params = params.set(key, this.filters[key]);
    });

    this.http.get<any>(this.apiUrl, { params }).subscribe(
      (response) => {
        this.dataSource.data = response.items;
        this.totalItems = response.totalItems;
        this.paginator.length = this.totalItems;
        this.paginator.pageSize = pageSize;
        this.paginator.pageIndex = pageIndex;
        this.isLoading = false;

        // Emit the fetched data
        this.tableItemsFetched.emit({
          items: response.items,
          totalItems: response.totalItems,
        });
      },
      (error) => {
        console.error('Error fetching data:', error);
        this.isLoading = false;
      }
    );
  }

  onPaginateChange(event: PageEvent): void {
    this.fetchData(event.pageIndex, event.pageSize);
  }

  onSortChange(sort: Sort): void {
    this.fetchData(
      this.paginator.pageIndex,
      this.paginator.pageSize,
      sort.active,
      sort.direction
    );
  }

  onRowDrop(event: CdkDragDrop<any[]>): void {
    if (this.reorderable) {
      moveItemInArray(
        this.dataSource.data,
        event.previousIndex,
        event.currentIndex
      );
      this.dataSource.data = [...this.dataSource.data]; // Trigger table update
      // Emit the updated order
      this.orderChanged.emit(this.dataSource.data);
    }
  }
}
