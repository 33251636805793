<div class="container">
  <h2 class="title-page">Mon compte</h2>
  <p class="sub-title">Editez vos information personnels et gérez vos cartes</p>
  <div class="sub-container">
    <div class="child">
      <mat-card appearance="outlined">
        <mat-card-header>
          <mat-card-title>Informations</mat-card-title>
          <mat-card-subtitle
            >Acceder et editez vos informations</mat-card-subtitle
          >
        </mat-card-header>
        <mat-card-actions>
          <button mat-button>Acceder</button>
        </mat-card-actions>
      </mat-card>
    </div>

    <div class="child">
      <mat-card appearance="outlined">
        <mat-card-header>
          <mat-card-title>Mot de passe</mat-card-title>
          <mat-card-subtitle>Modifiez votre mot de passe</mat-card-subtitle>
        </mat-card-header>
        <mat-card-actions>
          <button mat-button>Acceder</button>
        </mat-card-actions>
      </mat-card>
    </div>

    <div class="child">
      <mat-card appearance="outlined">
        <mat-card-header>
          <mat-card-title>Cartes de transport</mat-card-title>
          <mat-card-subtitle>Editer ma carte de transport </mat-card-subtitle>
        </mat-card-header>
        <mat-card-actions>
          <button mat-button>Acceder</button>
        </mat-card-actions>
      </mat-card>
    </div>

    <div class="child">
      <mat-card appearance="outlined">
        <mat-card-header>
          <mat-card-title>Cartes bancaires</mat-card-title>
          <mat-card-subtitle>Date d'échéance </mat-card-subtitle>
        </mat-card-header>
        <mat-card-actions>
          <button mat-button>Acceder</button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>
