import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  of,
  tap,
  throwError,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { SortDirection } from '@angular/material/sort';
import {
  IBikeType,
  IFile,
  IParkingAccessType,
  IParkingData,
  IParkingStatus,
} from '../common/management.interfaces';

interface PaginatedResponse<T> {
  items: T[];
  totalItems: number;
}

interface OccupationData {
  date: string;
  occupationPercentage: number;
}

@Injectable({
  providedIn: 'root',
})
export class ParkingManagementService {
  private _parkingStatus: IParkingStatus[] = [];
  private _parkingBikeType: IBikeType[] = [];
  private _parkingAccessType: IParkingAccessType[] = [];

  private apiUrl = environment.apiUrl;
  private parkingRefreshSubject = new BehaviorSubject<void>(undefined);

  private apiRouteParkingBikeType = 'parking/bikeType';
  private apiRouteParkingAccessType = 'parking/access';
  private headers: HttpHeaders;
  pageSize = 100;
  public sortDirection: 'asc' | 'desc' = 'asc';
  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  }

  get refresh$() {
    return this.parkingRefreshSubject.asObservable();
  }

  // Trigger refresh
  triggerRefresh() {
    this.parkingRefreshSubject.next();
  }

  parkings(
    sort: string,
    order: SortDirection,
    page: number
  ): Observable<{ items: []; total_count: number }> {
    return this.http.get<{ items: []; total_count: number }>(
      this.apiUrl +
        `/parking?page=${page + 1}&size=${this.pageSize}&sortField=${sort}&order=${order}&table_format=true`,
      { headers: this.headers }
    );
  }

  getAvailableParkings(): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + `/parking`, {
      headers: this.headers,
    });
  }

  parking(id: string): Observable<IParkingData> {
    return this.http.get<IParkingData>(this.apiUrl + `/parking/id/${id}`, {
      headers: this.headers,
    });
  }
  uploadFiles(formData: FormData): Observable<IFile[]> {
    return this.http.post<IFile[]>(`${this.apiUrl}/upload`, formData);
  }

  deleteFile(
    parkingId: string,
    fileId: string,
    type: 'file' | 'picture'
  ): Observable<void> {
    return this.http.delete<void>(
      `${this.apiUrl}/parking/${parkingId}/files/${fileId}?type=${type}`
    );
  }

  // Update your existing service methods to handle files
  updateParking(id: string, data: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/parking/${id}`, data);
  }

  createParking(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/parking`, data);
  }

  deleteParkingFile(
    id: string,
    fileId: string,
    type: string
  ): Observable<IParkingData> {
    return this.http.delete<IParkingData>(
      this.apiUrl + `/parking/${id}/file/${fileId}/type/${type}`
    );
  }

  openRemotely(parkingData: any): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/idcapt/remote-open`, {
        headers: this.headers,
        params: { ...parkingData }, // If you need to pass data as query params
      })
      .pipe(
        tap((response) => console.log('Remote open response:', response)),
        catchError((error) => {
          console.error('Error opening remotely:', error);
          return throwError(() => error);
        })
      );
  }

  parkingStatus(): Observable<IParkingStatus[]> {
    if (this._parkingStatus.length == 0) {
      return this.http
        .get<IParkingStatus[]>(this.apiUrl + `/parking/status`, {
          headers: this.headers,
        })
        .pipe(
          map((status) => {
            this._parkingStatus = status;
            return status;
          })
        );
    } else {
      return of(this._parkingStatus);
    }
  }

  parkingBikeType(): Observable<IBikeType[]> {
    if (this._parkingBikeType.length == 0) {
      return this.http
        .get<IBikeType[]>(this.apiUrl + `/parking/biketype`, {
          headers: this.headers,
        })
        .pipe(
          map((types) => {
            this._parkingBikeType = types;
            return types;
          })
        );
    } else {
      return of(this._parkingBikeType);
    }
  }

  parkingAccessType(): Observable<IParkingAccessType[]> {
    if (this._parkingBikeType.length == 0) {
      return this.http
        .get<IParkingAccessType[]>(this.apiUrl + `/parking/access`, {
          headers: this.headers,
        })
        .pipe(
          map((types) => {
            this._parkingAccessType = types;
            return types;
          })
        );
    } else {
      return of(this._parkingAccessType);
    }
  }

  // Get bike types
  getBikeTypes(): Observable<IBikeType[]> {
    return this.http
      .get<PaginatedResponse<IBikeType>>(`${this.apiUrl}/bike-types`)
      .pipe(map((response) => response.items));
  }

  // // Get access types
  // getAccessTypes(): Observable<IParkingAccessType[]> {
  //   return this.http
  //     .get<
  //       PaginatedResponse<IParkingAccessType>
  //     >(`${this.apiUrl}/parking/access`)
  //     .pipe(map((response) => response.items));
  // }

  parkingStatusByIs(id: string): IParkingStatus {
    return this._parkingStatus.find((status) => status.id === id);
  }

  parkingAccessTypeById(id: string): IParkingAccessType {
    return this._parkingAccessType.find((type) => type.id === id);
  }

  parkingByTypeById(id: string): IBikeType {
    return this._parkingBikeType.find((type) => type.id === id);
  }

  updateParkingBikeType(data: any, id: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .put<{
        bikeType: any;
      }>(this.apiUrl + '/' + this.apiRouteParkingBikeType + '/' + id, data, {
        headers,
      })
      .pipe(tap((response) => {}));
  }

  createParkingBikeType(data: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .post<{
        bikeType: any;
      }>(this.apiUrl + '/' + this.apiRouteParkingBikeType, data, {
        headers,
      })
      .pipe(tap((response) => {}));
  }

  updateParkingAccessType(id, data: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .put<{
        accessType: any;
      }>(this.apiUrl + '/' + this.apiRouteParkingAccessType + '/' + id, data, {
        headers,
      })
      .pipe(tap((response) => {}));
  }

  createParkingAccessType(data: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .post<{
        accessType: any;
      }>(this.apiUrl + '/' + this.apiRouteParkingAccessType, data, {
        headers,
      })
      .pipe(tap((response) => {}));
  }

  // File operations
  uploadFile(formData: FormData): Observable<IFile> {
    return this.http.post<IFile>(`${this.apiUrl}/upload`, formData);
  }

  getParkingOccupation(): Observable<OccupationData[]> {
    return this.http.get<OccupationData[]>(
      `${this.apiUrl}/parking/occupation/daily`,
      {
        params: {
          days: '15',
        },
        headers: this.headers,
      }
    );
  }
}
