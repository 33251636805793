import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UserManagementService } from '../user-management.service';
import { firstValueFrom } from 'rxjs';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';

interface CityGroup {
  _id: string;
  name: string;
}

@Component({
  selector: 'app-user-management-edit',
  templateUrl: './user-management-edit.component.html',
  styleUrls: ['./user-management-edit.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatIconModule,
  ],
})
export class UserManagementEditComponent implements OnInit {
  @ViewChild('profilePictureInput') profilePictureInput!: ElementRef;

  isEdit = false;
  userForm!: FormGroup;
  cityGroups: CityGroup[] = [];

  roles = [
    { value: 1, label: 'User' },
    { value: 2, label: 'Admin' },
    { value: 7, label: 'Super Admin' },
  ];

  constructor(
    private fb: FormBuilder,
    private userService: UserManagementService,
    public dialogRef: MatDialogRef<UserManagementEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer
  ) {
    this.isEdit = data && data._id != null;
    this.initializeForm();
  }

  async ngOnInit() {
    await this.loadCityGroups();
    if (this.data?._id) {
      await this.loadUserData();
    }
  }

  private initializeForm() {
    this.userForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      passwordHash: [
        '',
        this.isEdit ? [] : [Validators.required, Validators.minLength(8)],
      ],
      roles: ['', Validators.required],
      isActive: [true],
      cityGroup: ['', Validators.required],
    });
  }

  private async loadCityGroups() {
    try {
      const response = await firstValueFrom(this.userService.getCityGroups());
      this.cityGroups = response;
    } catch (error) {
      console.error('Error loading city groups:', error);
    }
  }

  private async loadUserData() {
    try {
      const user = await firstValueFrom(
        this.userService.getUserById(this.data._id)
      );

      this.userForm.patchValue({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        roles: user.roles,
        isActive: user.isActive,
        cityGroup: user.cityGroup?._id,
      });

      // Clear password validators for edit mode
      const passwordControl = this.userForm.get('passwordHash');
      if (passwordControl) {
        passwordControl.clearValidators();
        passwordControl.updateValueAndValidity();
      }
    } catch (error) {
      console.error('Error loading user data:', error);
    }
  }

  saveUser() {
    if (this.userForm.valid) {
      const userData = this.userForm.value;

      const saveRequest = this.isEdit
        ? this.userService.updateUser(this.data._id, userData)
        : this.userService.createUser(userData);

      saveRequest.subscribe({
        next: (response) => {
          this.dialogRef.close(response);
        },
        error: (error) => {
          console.error('Error saving user:', error);
        },
      });
    } else {
      this.userForm.markAllAsTouched();
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
