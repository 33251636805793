<p>dashboard-number-of-opening-by-parking works!</p>
<div>
  <canvas
    baseChart
    [data]="barChartData"
    [labels]="barChartLabels"
    [options]="barChartOptions"
    [legend]="barChartLegend"
    [type]="barChartType"
  >
  </canvas>
</div>
