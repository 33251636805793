// parking-occupation-chart.component.ts
import { Component, OnInit, ViewChild } from '@angular/core';
import { Chart, ChartData, ChartType, registerables } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

Chart.register(...registerables);

@Component({
  selector: 'app-parking-occupation-chart',
  standalone: true,
  imports: [BaseChartDirective],
  template: `
    <div class="chart-container">
      <canvas
        baseChart
        [data]="chartData"
        [options]="chartOptions"
        [type]="chartType"
      >
      </canvas>
    </div>
  `,
  styles: [
    `
      .chart-container {
        height: 200px;
        width: 100%;
        margin: 0 auto;
      }
    `,
  ],
})
export class ParkingOccupationChartComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective<'bar'> | undefined;

  public chartType: ChartType = 'bar';

  public chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          stepSize: 20,
          callback: (value: number) => `${value}%`,
        },
        title: {
          display: true,
          text: "Taux d'occupation",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Taux d'occupation des parkings",
        padding: 20,
      },
    },
  };

  public chartData: ChartData<'bar'> = {
    labels: this.getLast15Days(),
    datasets: [
      {
        data: this.generateRealisticOccupationData(),
        backgroundColor: 'rgba(75, 112, 226, 0.7)',
        borderColor: '#4B70E2',
        borderWidth: 1,
      },
    ],
  };

  ngOnInit(): void {}

  private getLast15Days(): string[] {
    return Array.from({ length: 15 }, (_, i) => {
      const date = new Date();
      date.setDate(date.getDate() - (14 - i));
      return date.toLocaleDateString('fr-FR', {
        day: '2-digit',
        month: '2-digit',
      });
    });
  }

  private generateRealisticOccupationData(): number[] {
    // Generate base weekday/weekend pattern
    return Array.from({ length: 15 }, (_, i) => {
      const date = new Date();
      date.setDate(date.getDate() - (14 - i));
      const isWeekend = date.getDay() === 0 || date.getDay() === 6;

      // Base occupation
      let baseOccupation = isWeekend
        ? Math.floor(Math.random() * 20) + 30 // 30-50% base for weekends
        : Math.floor(Math.random() * 30) + 60; // 60-90% base for weekdays

      // Add some random variation (±10%)
      const variation = Math.floor(Math.random() * 20) - 10;

      // Ensure the final value is between 0 and 100
      return Math.min(100, Math.max(0, baseOccupation + variation));
    });
  }
}
