import { Component } from '@angular/core';
import { TicketStatusComponent } from '../ticket-management/ticket-status/ticket-status.component';
import { TicketPriorityComponent } from '../ticket-management/ticket-priority/ticket-priority.component';
import { TicketCategoryComponent } from '../ticket-management/ticket-category/ticket-category.component';
import { SubscriptionPlanComponent } from '../subscription-plan/subscription-plan.component';
import { ParkingBikeTypeComponent } from '../parking-management/parking-bike-type/parking-bike-type.component';
import { ParkingAccessTypeComponent } from '../parking-management/parking-access-type/parking-access-type.component';
import { CityGroupsManagementComponent } from '../city-groups-management/city-groups-management.component';

@Component({
  selector: 'app-global-parameters',
  standalone: true,
  imports: [
    TicketStatusComponent,
    TicketPriorityComponent,
    TicketCategoryComponent,
    SubscriptionPlanComponent,
    ParkingBikeTypeComponent,
    ParkingAccessTypeComponent,
    CityGroupsManagementComponent,
  ],
  templateUrl: './global-parameters.component.html',
  styleUrl: './global-parameters.component.css',
})
export class GlobalParametersComponent {}
