<div class="ticket-container" *ngIf="data">
  <div class="ticket-card">
    <!-- Header -->
    <div class="card-header">
      <button mat-raised-button color="primary" (click)="editTicket(data)">
        <mat-icon>edit</mat-icon>
        Editer
      </button>
    </div>

    <!-- Tags -->
    <div class="ticket-tags">
      <div class="tags-group">
        <mat-chip class="priority-chip">{{
          data.priority?.label || "Non défini"
        }}</mat-chip>
        <mat-chip class="type-chip">Intervention corrective</mat-chip>
      </div>
      <mat-chip class="status-chip"
        >Status: {{ data.status?.label || "Non défini" }}</mat-chip
      >
    </div>

    <div class="ticket-content">
      <!-- Title -->
      <h3 class="title">{{ data.title || "Sans titre" }}</h3>

      <!-- Meta Info -->
      <div class="meta-info">
        <p class="date">
          <mat-icon>event</mat-icon>
          <span>Déclaré le </span>{{ data.incidentDate || "Date non définie" }}
        </p>
        <p class="reporter">
          <mat-icon>person</mat-icon>
          <span>Par </span>{{ data.reporter || "Utilisateur inconnu" }}
        </p>
      </div>

      <!-- Description -->
      <div class="description-section">
        <p class="list-detail description">
          <span>Description: </span
          >{{ data.description || "Aucune description" }}
        </p>
      </div>

      <!-- Details Grid -->
      <div class="details-grid">
        <!-- Info Column -->
        <div class="info-column">
          <p class="list-detail">
            <span>Categorie: </span>{{ data.category || "Non catégorisé" }}
          </p>
          <p class="list-detail">
            <span>Adresse: </span>{{ data.address || "Adresse non renseignée" }}
          </p>
          <p class="list-detail">
            <span>Emplacement: </span>{{ data.location || "Non spécifié" }}
          </p>
        </div>

        <!-- Photos Column -->
        <div class="photos-column" *ngIf="data.pictures?.length">
          <p class="list-detail photos-title">
            <span>Photos</span>
          </p>
          <div class="photos-grid">
            <div class="photo-card" *ngFor="let photo of data.pictures">
              <img
                class="photo-image"
                [src]="photo.url"
                [alt]="photo.description || ''"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Show a message when no data is available -->
<div class="no-data-container" *ngIf="!data">
  <p class="no-data-message">Sélectionnez un incident pour voir les détails</p>
</div>
