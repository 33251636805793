// import { Component } from '@angular/core';

import { CommonModule } from '@angular/common';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ParkingManagementService } from '../parking-management.service';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatStepperModule } from '@angular/material/stepper';

import { GoogleMapsModule } from '@angular/google-maps';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

interface IFile {
  fileId: string;
  originalName: string;
  loaded?: boolean;
  error?: boolean;
}

@Component({
  selector: 'app-parking-management-edit',
  standalone: true,
  imports: [
    MatStepperModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatOptionModule,
    MatSelectModule,
    GoogleMapsModule,
  ],
  templateUrl: './parking-management-edit.component.html',
  styleUrl: './parking-management-edit.component.scss',
})
export class ParkingManagementEditComponent {
  @ViewChild('fileInput', { static: true }) fileInput!: ElementRef;
  @ViewChild('documentInput', { static: true }) documentInput!: ElementRef;
  center: google.maps.LatLngLiteral = { lat: 46.227638, lng: 2.213749 };
  zoom = 5;
  markerPosition: google.maps.LatLngLiteral | null = null;
  markerOptions: google.maps.MarkerOptions = { draggable: true };
  loading = false;

  apiUrl = environment.apiUrl;

  // Add file handling properties
  pictures: IFile[] = [];
  documents: IFile[] = [];

  // private map: google.maps.Map | null = null;
  // private marker: google.maps.Marker | null = null;
  isEdit = false; // Default to creation mode
  form: FormGroup;
  isLinear = true; // For stepper to require completion of each step

  // defaultCapacities = [
  //   {
  //     bikeType: '6746eedc3248ed16db682310',
  //     totalPlaces: 10,
  //     availablePlaces: 8,
  //   },
  //   {
  //     bikeType: '6746ef143248ed16db682313',
  //     totalPlaces: 4,
  //     availablePlaces: 5,
  //   },
  // ];

  // Observables for dropdowns
  accessTypes$ = this.parkingManagementService.parkingAccessType();
  bikeTypes$ = this.parkingManagementService.parkingBikeType();
  parkingStatus$ = this.parkingManagementService.parkingStatus();

  constructor(
    public dialogRef: MatDialogRef<ParkingManagementEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private parkingManagementService: ParkingManagementService,
    private _formBuilder: FormBuilder,
    private sanitizer: DomSanitizer
  ) {
    this.isEdit = !!(data && data.id);

    this.initializeForm();
  }

  ngOnInit() {
    if (this.isEdit) {
      this.loadExistingData();
    } else {
      // Add default capacities
      const defaultCapacities = [
        {
          bikeType: '6746eedc3248ed16db682310',
          totalPlaces: 10,
          availablePlaces: 8,
        },
        {
          bikeType: '6746ef143248ed16db682313',
          totalPlaces: 4,
          availablePlaces: 5,
        },
      ];
      defaultCapacities.forEach((cap) => {
        this.addCapacity(cap);
      });
    }
  }

  addMarker(event: google.maps.MapMouseEvent) {
    if (event.latLng) {
      const position = event.latLng.toJSON();
      this.markerPosition = position;
      this.updateAddress(position);

      // Update form with coordinates
      this.form.get('location')?.patchValue({
        latitude: position.lat,
        longitude: position.lng,
      });
    }
  }

  markerDragEnd(event: google.maps.MapMouseEvent) {
    if (event.latLng) {
      const position = event.latLng.toJSON();
      this.markerPosition = position;
      this.updateAddress(position);

      // Update form with coordinates
      this.form.get('location')?.patchValue({
        latitude: position.lat,
        longitude: position.lng,
      });
    }
  }

  private updateAddress(location: google.maps.LatLngLiteral) {
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({ location }, (results, status) => {
      if (status === 'OK' && results?.[0]) {
        const addressComponents = results[0].address_components;
        let street = '',
          city = '',
          postalCode = '',
          country = '';

        for (const component of addressComponents) {
          const types = component.types;

          if (types.includes('street_number') || types.includes('route')) {
            street += component.long_name + ' ';
          }
          if (types.includes('locality')) {
            city = component.long_name;
          }
          if (types.includes('postal_code')) {
            postalCode = component.long_name;
          }
          if (types.includes('country')) {
            country = component.long_name;
          }
        }

        this.form.get('location')?.patchValue({
          street: street.trim(),
          city,
          postalCode,
          country,
          latitude: location.lat,
          longitude: location.lng,
        });
      }
    });
  }

  detectLocation() {
    const locationForm = this.form.get('location');
    if (!locationForm) return;

    const address = `${locationForm.get('street')?.value}, ${locationForm.get('city')?.value}, ${locationForm.get('postalCode')?.value}, ${locationForm.get('country')?.value}`;

    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
      if (status === 'OK' && results?.[0]) {
        const location = results[0].geometry.location;
        this.markerPosition = {
          lat: location.lat(),
          lng: location.lng(),
        };
        this.center = this.markerPosition;
        this.zoom = 15;

        // Update form with precise coordinates
        locationForm.patchValue({
          latitude: location.lat(),
          longitude: location.lng(),
        });
      }
    });
  }
  async onFileSelected(event: Event, type: 'file' | 'picture') {
    const element = event.target as HTMLInputElement;
    const fileList: FileList | null = element.files;

    if (fileList) {
      const files = Array.from(fileList);
      const formData = new FormData();

      files.forEach((file) => {
        formData.append('files', file);
      });

      try {
        this.loading = true;
        const response = await firstValueFrom(
          this.parkingManagementService.uploadFiles(formData)
        );

        if (!response || !Array.isArray(response)) {
          throw new Error('Invalid upload response');
        }

        const validFiles = response.filter((file) => file && file.fileId);
        if (validFiles.length === 0) {
          throw new Error('No valid files uploaded');
        }

        const fileIds = validFiles.map((file) => file.fileId);

        // Update parking with new file IDs
        const currentArray =
          type === 'file' ? this.data.documents : this.data.pictures;
        const existingIds = (currentArray || [])
          .map((item: any) => item.fileId || item)
          .filter(Boolean);

        const updateData = {
          [type === 'file' ? 'documents' : 'pictures']: [
            ...existingIds,
            ...fileIds,
          ],
        };

        const updatedParking = await firstValueFrom(
          this.parkingManagementService.updateParking(this.data.id, updateData)
        );

        this.data = updatedParking;
        this.updateFileArrays();

        // Clear the input
        element.value = '';
      } catch (error) {
        console.error('Error uploading files:', error);
      } finally {
        this.loading = false;
      }
    }
  }

  onImageLoad(picture: IFile) {
    picture.loaded = true;
    picture.error = false;
  }

  onImageError(picture: IFile) {
    picture.loaded = false;
    picture.error = true;
    console.error(`Failed to load image: ${picture.fileId}`);
  }

  updateFileArrays() {
    if (this.data?.pictures) {
      this.pictures = this.data.pictures
        .filter((pic: any) => pic && (pic.fileId || typeof pic === 'string')) // Filter out invalid entries
        .map((pic: any) => ({
          fileId: pic.fileId || pic,
          originalName: pic.originalName || `File ${pic.fileId || pic}`,
          loaded: false,
          error: false,
        }));
    }

    if (this.data?.documents) {
      this.documents = this.data.documents
        .filter((doc: any) => doc && (doc.fileId || typeof doc === 'string'))
        .map((doc: any) => ({
          fileId: doc.fileId || doc,
          originalName: doc.originalName || `File ${doc.fileId || doc}`,
          loaded: false,
          error: false,
        }));
    }
  }
  async deleteFile(fileId: string, type: 'file' | 'picture') {
    // try {
    //   await firstValueFrom(
    //     this.parkingManagementService.deleteFile(this.data.id, fileId, type)
    //   );
    //   const updatedParking = await firstValueFrom(
    //     this.parkingManagementService.getById(this.data.id)
    //   );
    //   this.data = updatedParking;
    // } catch (error) {
    //   console.error('Error deleting file:', error);
    // }
  }

  openFile(file: any, type: 'picture' | 'file') {
    const url = this.getFileUrl(file.fileId);
    window.open(url as string, '_blank', 'width=1024,height=768');
  }

  getFileUrl(fileId: string): SafeUrl | null {
    if (!fileId) {
      return null;
    }
    try {
      const url = `${this.apiUrl}/upload/${fileId}`;
      return this.sanitizer.bypassSecurityTrustUrl(url);
    } catch (error) {
      console.error('Error creating file URL:', error);
      return null;
    }
  }

  trackByFileId(index: number, item: any) {
    return item.fileId;
  }
  // detectLocation() {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         const location = {
  //           lat: position.coords.latitude,
  //           lng: position.coords.longitude,
  //         };

  //         this.center = location;
  //         this.markerPosition = location;
  //         this.zoom = 15;
  //         this.updateAddress(location);
  //       },
  //       (error) => {
  //         console.error('Error getting location:', error);
  //         // Handle error appropriately
  //       }
  //     );
  //   } else {
  //     console.error('Geolocation is not supported by this browser.');
  //     // Handle lack of support
  //   }
  // }

  private initializeForm() {
    this.form = this._formBuilder.group({
      informations: this._formBuilder.group({
        label: [this.data?.name || '', Validators.required],
        description: [this.data?.description || '', Validators.required],
      }),
      capacityGroup: this._formBuilder.group({
        capacity: this._formBuilder.array([]),
      }),
      access: this._formBuilder.group({
        accessType: [this.data?.accessType?.id || '', Validators.required],
        status: [this.data?.status?.id || '', Validators.required],
        enabled: [true],
      }),
      location: this._formBuilder.group({
        street: [this.data?.street || '', Validators.required],
        city: [this.data?.city || '', Validators.required],
        postalCode: [this.data?.postalCode || '', Validators.required],
        country: [this.data?.country || '', Validators.required],
        latitude: [this.data?.location?.latitude || ''],
        longitude: [this.data?.location?.longitude || ''],
      }),
      equipment: this._formBuilder.group({
        cameraId: [
          this.data?.cameraId || '',
          [Validators.required, Validators.min(0)],
        ],
        readerId: [
          this.data?.readerId || '',
          [Validators.required, Validators.min(0)],
        ],
      }),
      // Add files step form group
      filesStep: this._formBuilder.group({}),
    });
  }

  get capacityArray() {
    return this.form.get('capacityGroup.capacity') as FormArray;
  }

  addCapacity(data?: any) {
    const newCapacity = this._formBuilder.group({
      bikeType: [data?.bikeType || '', Validators.required],
      totalPlaces: [
        data?.totalPlaces || 0,
        [Validators.required, Validators.min(0)],
      ],
      availablePlaces: [
        data?.availablePlaces || 0,
        [Validators.required, Validators.min(0)],
      ],
    });

    this.capacityArray.push(newCapacity);
  }

  removeCapacity(index: number) {
    if (this.capacityArray.length > 1) {
      this.capacityArray.removeAt(index);
    }
  }
  private loadExistingData() {
    if (this.data) {
      // Load general information
      this.form.get('informations')?.patchValue({
        label: this.data.name,
        description: this.data.description,
      });

      // Load access information
      this.form.get('access')?.patchValue({
        accessType: this.data.accessType.id,
        status: this.data.status.id,
        enabled: true, // You might want to add this field in your backend if needed
      });

      // Load location information
      this.form.get('location')?.patchValue({
        street: this.data.street,
        city: this.data.city,
        postalCode: this.data.postalCode,
        country: this.data.country,
        latitude: this.data.location.latitude,
        longitude: this.data.location.longitude,
      });

      // Update map marker position
      if (this.data.location) {
        this.markerPosition = {
          lat: this.data.location.latitude,
          lng: this.data.location.longitude,
        };
        this.center = this.markerPosition;
        this.zoom = 15;
      }

      // Load equipment information
      this.form.get('equipment')?.patchValue({
        cameraId: this.data.cameraId,
        readerId: this.data.readerId,
      });

      if (this.data.location) {
        this.markerPosition = {
          lat: this.data.location.latitude,
          lng: this.data.location.longitude,
        };
        this.center = this.markerPosition;
        this.zoom = 15;
      }

      if (this.data.pictures) {
        this.pictures = this.data.pictures.map((pic: any) => ({
          fileId: pic.fileId,
          originalName: pic.originalName,
          loaded: false,
          error: false,
        }));
      }

      if (this.data.documents) {
        this.documents = this.data.documents.map((doc: any) => ({
          fileId: doc.fileId,
          originalName: doc.originalName,
          loaded: false,
          error: false,
        }));
      }

      this.updateFileArrays();

      // Load capacity information
      if (this.data.capacity?.length) {
        // Clear existing capacity array
        while (this.capacityArray.length) {
          this.capacityArray.removeAt(0);
        }

        // Add each capacity
        this.data.capacity.forEach((cap) => {
          this.addCapacity({
            bikeType: cap.bikeType.id,
            totalPlaces: cap.totalPlaces,
            availablePlaces: cap.availablePlaces,
          });
        });
      } else {
        this.addCapacity();
      }
    }
  }

  // loading = false;
  create(): void {
    if (this.form.valid) {
      this.loading = true;
      const formData = {
        name: this.form.get('informations')?.get('label')?.value,
        description: this.form.get('informations')?.get('description')?.value,
        accessType: this.form.get('access')?.get('accessType')?.value,
        status: this.form.get('access')?.get('status')?.value,
        capacity: this.form
          .get('capacityGroup')
          ?.get('capacity')
          ?.value.map((cap: any) => ({
            totalPlaces: Number(cap.totalPlaces),
            availablePlaces: Number(cap.availablePlaces),
            bikeType: cap.bikeType.toString(), // Ensure it's a string
          })),
        pictures: this.pictures.map((pic) => pic.fileId.toString()),
        documents: this.documents.map((doc) => doc.fileId.toString()),
        users: [],
        postalCode: this.form.get('location')?.get('postalCode')?.value,
        country: this.form.get('location')?.get('country')?.value,
        cameraId: Number(this.form.get('equipment')?.get('cameraId')?.value),
        readerId: Number(this.form.get('equipment')?.get('readerId')?.value),
        street: this.form.get('location')?.get('street')?.value,
        city: this.form.get('location')?.get('city')?.value,
        location: {
          latitude: Number(this.form.get('location')?.get('latitude')?.value),
          longitude: Number(this.form.get('location')?.get('longitude')?.value),
        },
      };

      this.parkingManagementService.createParking(formData).subscribe({
        next: (response) => {
          this.loading = false;
          this.dialogRef.close(response);
        },
        error: (error) => {
          this.loading = false;
          console.error('Creation failed:', error);
        },
      });
    }
  }

  update(): void {
    if (this.form.valid) {
      this.loading = true;
      const formData = {
        name: this.form.get('informations')?.get('label')?.value,
        description: this.form.get('informations')?.get('description')?.value,
        accessType: this.form.get('access')?.get('accessType')?.value,
        status: this.form.get('access')?.get('status')?.value,
        capacity: this.form
          .get('capacityGroup')
          ?.get('capacity')
          ?.value.map((cap: any) => ({
            totalPlaces: Number(cap.totalPlaces),
            availablePlaces: Number(cap.availablePlaces),
            bikeType: cap.bikeType, // Don't convert this to string
          })),
        postalCode: this.form.get('location')?.get('postalCode')?.value,
        country: this.form.get('location')?.get('country')?.value,
        cameraId: Number(this.form.get('equipment')?.get('cameraId')?.value),
        readerId: Number(this.form.get('equipment')?.get('readerId')?.value),
        street: this.form.get('location')?.get('street')?.value,
        city: this.form.get('location')?.get('city')?.value,
        location: {
          latitude: Number(this.form.get('location')?.get('latitude')?.value),
          longitude: Number(this.form.get('location')?.get('longitude')?.value),
        },
      };

      this.parkingManagementService
        .updateParking(this.data.id, formData)
        .subscribe({
          next: (response) => {
            this.loading = false;
            this.dialogRef.close(response);
          },
          error: (error) => {
            this.loading = false;
            console.error('Update failed:', error);
          },
        });
    }
  }
}
