import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

export enum Role {
  USER = 1,
  ADMIN = 2,
  SUPER_ADMIN = 7,
}

@Component({
  selector: 'app-table-widget-role',
  standalone: true,
  imports: [NgClass],
  templateUrl: './table-widget-role.component.html',
  styleUrl: './table-widget-role.component.css',
})
export class TableWidgetRoleComponent {
  @Input() data: any;

  getRole() {
    switch (this.data) {
      case Role.ADMIN:
        return 'Administrateur';
      case Role.USER:
        return 'Utilisateur';
      case Role.SUPER_ADMIN:
        return 'Admin BH';
    }
  }

  roles = Object.values(Role); // Example statuses to loop through
  role: Role = Role.USER; // Example single status

  getRoleClass(role: Role): string {
    // console.log(role);
    switch (role) {
      case Role.ADMIN:
        return 'pill-bkg-grey';
      case Role.USER:
        return 'pill-bkg-red';
      case Role.SUPER_ADMIN:
        return 'pill-bkg-green';
    }
  }
}
