import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StripeFactoryService, StripeInstance } from 'ngx-stripe';
import { switchMap } from 'rxjs';
import { environment } from '../../environments/environment';

interface IStripeSession {
  id: string;
}
@Component({
  selector: 'app-checkout',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './checkout.component.html',
  styleUrl: './checkout.component.css',
})
export class CheckoutComponent {
  public stripe!: StripeInstance;
  public stripeAmount!: any;
  public stripeProductName!: any;
  private stripePublicKey: string = environment.stripePublicKey;
  private apiUrl = environment.apiUrl;
  isLoading: boolean = false;
  id = 0;
  currentSelection: { id: number; price: number; productName: string };

  data = [
    { id: 1, productName: 'Ticket 1 jour', price: 4 },
    { id: 2, productName: 'Pass 1 mois', price: 10 },
    { id: 3, productName: 'Pass 1 an', price: 30 },
  ];

  private route = inject(ActivatedRoute);
  private router = inject(Router);

  constructor(
    private http: HttpClient,
    private stripeFactory: StripeFactoryService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = +params.id;
      console.log(this.id);
      // found matchin
      for (let d of this.data) {
        if (d.id === this.id) {
          this.currentSelection = d;
          break;
        }
      }
    });

    this.stripe = this.stripeFactory.create(this.stripePublicKey);
    this.stripeAmount = this.currentSelection.price;
    this.stripeProductName = this.currentSelection.productName;
  }

  checkout() {
    this.isLoading = true;

    this.http
      .post(
        this.apiUrl + 'orders/create-checkout-session',
        {
          data: {
            amount: this.stripeAmount * 100,
            productName: this.stripeProductName,
          },
        },
        { observe: 'response' }
      )
      .pipe(
        switchMap((response: HttpResponse<Object>) => {
          const session: IStripeSession = response.body as IStripeSession;
          return this.stripe.redirectToCheckout({ sessionId: session.id });
        })
      )
      .subscribe((result) => {
        // If `redirectToCheckout` fails due to a browser or network
        if (result.error) {
          console.log(result.error);
        }
      });
  }
}
