<div class="table-container">
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>

  <!-- Filters and Column Toggles Section -->
  <div class="">
    <mat-accordion *ngIf="hasFiltersOrOptions()">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>Filtres et options</mat-panel-title>
        </mat-expansion-panel-header>

        <!-- Column Visibility Toggles -->
        <div class="column-toggles" *ngIf="showColumnVisibilityToggle">
          <ng-container *ngFor="let column of columns">
            <mat-checkbox
              [(ngModel)]="column.displayed"
              [checked]="column.displayed !== false"
              (change)="updateDisplayedColumns()"
            >
              {{ column.header }}
            </mat-checkbox>
          </ng-container>
        </div>

        <!-- Filters Section -->
        <div class="filters padding-20">
          <div *ngFor="let column of columns" class="filter-item">
            <!-- Switch Filter -->
            <ng-container *ngIf="column.filter?.type === 'switch'">
              <mat-slide-toggle
                [(ngModel)]="filters[column.columnDef]"
                (change)="onFilterChange()"
              >
                {{ column.filter.text }}
              </mat-slide-toggle>
            </ng-container>

            <!-- Add other filter types here -->
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <!-- Table Section -->
  <table
    cdkDropList
    (cdkDropListDropped)="onRowDrop($event)"
    mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="onSortChange($event)"
    class="mat-elevation-z8"
  >
    <!-- Drag Handle Column -->
    <ng-container matColumnDef="drag">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <mat-icon cdkDragHandle class="drag-handle">drag_handle</mat-icon>
      </td>
    </ng-container>

    <ng-container
      *ngFor="let column of columns"
      [matColumnDef]="column.columnDef"
    >
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <strong>{{ column.header }}</strong>
      </th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="column.component; else defaultContent">
          <app-table-dynamic-column
            [component]="getComponent(column.component)"
            [data]="column.useFullData ? element : element[column.columnDef]"
          ></app-table-dynamic-column>
        </ng-container>
        <ng-template #defaultContent>
          <span>{{ element[column.columnDef] }}</span>
        </ng-template>
      </td>
    </ng-container>

    <!-- Actions Column -->

    <ng-container *ngIf="enableActions" matColumnDef="actions">
      <th
        mat-header-cell
        *matHeaderCellDef
        [hidden]="!(actions && actions.length > 0)"
      ></th>
      <td
        mat-cell
        *matCellDef="let element"
        [hidden]="!(actions && actions.length > 0)"
      >
        <div class="actions-container">
          <ng-container *ngIf="actions && actions.length > 0">
            <ng-container *ngFor="let action of actions">
              <button
                mat-button
                color="primary"
                (click)="onActionClick(action.call, element)"
              >
                {{ action.label }}
              </button>
            </ng-container>
          </ng-container>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" cdkDrag></tr>
  </table>

  <mat-paginator
    #paginator
    *ngIf="enablePagination"
    [length]="totalItems"
    [pageSize]="pageSize"
    [pageSizeOptions]="[5, 10, 25, 100]"
    (page)="onPaginateChange($event)"
  >
  </mat-paginator>
</div>
