import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-order-validated',
  standalone: true,
  imports: [],
  templateUrl: './order-validated.component.html',
  styleUrl: './order-validated.component.css',
})
export class OrderValidatedComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private cdr: ChangeDetectorRef // Inject ChangeDetectorRef
  ) {}
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      console.log(params);
      if (params) {
        setTimeout(() => {
          // this.loginService.currentState = 'connected';
        });
      }
    });
  }
}
