// directives/role.directive.ts
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { LoginService } from '../login/login.service';

@Directive({
  selector: '[appHasRole]',
  standalone: true,
})
export class HasRoleDirective {
  @Input() set appHasRole(role: number) {
    this.loginService.profile$.subscribe((profile) => {
      if (profile && profile.roles) {
        if ((profile.roles & role) !== 0) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      }
    });
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private loginService: LoginService
  ) {}
}
