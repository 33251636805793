// subscribe.component.ts
import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router, RouterLink } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-subscribe',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterLink,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
  ],
  template: `
    <div class="register-container">
      <div class="register-card">
        <h2 class="register-title">Inscription au service</h2>
        <p class="register-subtitle">
          Inscrivez vous et reservez votre parking immédiatement
        </p>

        <form
          [formGroup]="subscribeForm"
          (ngSubmit)="onSubmit()"
          class="register-form"
        >
          <div class="name-row">
            <mat-form-field appearance="outline">
              <mat-label>Nom</mat-label>
              <input matInput formControlName="lastName" />
              <mat-icon matPrefix>person</mat-icon>
              <mat-error
                *ngIf="subscribeForm.get('lastName')?.errors?.['required']"
              >
                Le nom est requis
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Prénom</mat-label>
              <input matInput formControlName="firstName" />
              <mat-icon matPrefix>person_outline</mat-icon>
              <mat-error
                *ngIf="subscribeForm.get('firstName')?.errors?.['required']"
              >
                Le prénom est requis
              </mat-error>
            </mat-form-field>
          </div>

          <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput type="email" formControlName="email" />
            <mat-icon matPrefix>email</mat-icon>
            <mat-error *ngIf="subscribeForm.get('email')?.errors?.['required']">
              L'email est requis
            </mat-error>
            <mat-error *ngIf="subscribeForm.get('email')?.errors?.['email']">
              L'email n'est pas valide
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Mot de passe</mat-label>
            <input
              matInput
              [type]="hidePassword ? 'password' : 'text'"
              formControlName="password"
            />
            <mat-icon matPrefix>lock</mat-icon>
            <button
              mat-icon-button
              matSuffix
              type="button"
              (click)="hidePassword = !hidePassword"
            >
              <mat-icon>{{
                hidePassword ? 'visibility_off' : 'visibility'
              }}</mat-icon>
            </button>
            <mat-error
              *ngIf="subscribeForm.get('password')?.errors?.['required']"
            >
              Le mot de passe est requis
            </mat-error>
            <mat-error
              *ngIf="subscribeForm.get('password')?.errors?.['minlength']"
            >
              Le mot de passe doit contenir au moins 6 caractères
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Confirmation du mot de passe</mat-label>
            <input
              matInput
              [type]="hideConfirmPassword ? 'password' : 'text'"
              formControlName="passwordConfirm"
            />
            <mat-icon matPrefix>lock_outline</mat-icon>
            <button
              mat-icon-button
              matSuffix
              type="button"
              (click)="hideConfirmPassword = !hideConfirmPassword"
            >
              <mat-icon>{{
                hideConfirmPassword ? 'visibility_off' : 'visibility'
              }}</mat-icon>
            </button>
            <mat-error
              *ngIf="subscribeForm.get('passwordConfirm')?.errors?.['required']"
            >
              La confirmation du mot de passe est requise
            </mat-error>
            <mat-error
              *ngIf="
                subscribeForm.get('passwordConfirm')?.errors?.[
                  'passwordMismatch'
                ]
              "
            >
              Les mots de passe ne correspondent pas
            </mat-error>
          </mat-form-field>

          <button
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="!subscribeForm.valid || isLoading"
            class="submit-button"
          >
            <span *ngIf="!isLoading">M'inscrire</span>
            <mat-spinner *ngIf="isLoading" diameter="20"></mat-spinner>
          </button>
        </form>

        <div class="login-prompt">
          <span>Déjà inscrit?</span>
          <a routerLink="/login" class="login-link">Se connecter</a>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .register-container {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        padding: 20px;
        background-color: #f5f5f5;
      }

      .register-card {
        background: white;
        border-radius: 12px;
        padding: 40px;
        width: 100%;
        max-width: 600px;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
      }

      .register-title {
        font-size: 24px;
        font-weight: 600;
        color: #333;
        margin: 0;
        text-align: center;
      }

      .register-subtitle {
        text-align: center;
        color: #666;
        margin: 8px 0 32px;
      }

      .register-form {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      .name-row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
      }

      mat-form-field {
        width: 100%;
      }

      .submit-button {
        height: 48px;
        font-size: 16px;
        margin-top: 16px;

        mat-spinner {
          margin: 0 auto;
        }
      }

      .login-prompt {
        margin-top: 24px;
        text-align: center;
        font-size: 14px;
        color: #666;

        .login-link {
          color: #1976d2;
          margin-left: 8px;
          text-decoration: none;
          font-weight: 500;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      @media (max-width: 600px) {
        .register-card {
          padding: 24px;
        }

        .name-row {
          grid-template-columns: 1fr;
        }

        .register-title {
          font-size: 20px;
        }

        .register-subtitle {
          font-size: 14px;
          margin-bottom: 24px;
        }
      }
    `,
  ],
})
export class SubscribeComponent {
  private router = inject(Router);
  private fb = inject(FormBuilder);

  subscribeForm: FormGroup;
  isLoading = false;
  hidePassword = true;
  hideConfirmPassword = true;

  constructor(private http: HttpClient) {
    this.subscribeForm = this.fb.group(
      {
        lastName: ['', Validators.required],
        firstName: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(6)]],
        passwordConfirm: ['', [Validators.required]],
      },
      { validators: this.passwordMatchValidator }
    );
  }

  passwordMatchValidator(g: FormGroup) {
    return g.get('password')?.value === g.get('passwordConfirm')?.value
      ? null
      : { passwordMismatch: true };
  }

  onSubmit() {
    if (this.subscribeForm.valid) {
      this.isLoading = true;

      // First API call
      this.http
        .post('http://localhost:7777/subscribe', {
          email: this.subscribeForm.get('email')?.value,
        })
        .subscribe({
          next: () => {
            console.log('sending email');
            this.completeRegistration();
          },
          error: (error) => {
            console.error('Error sending email:', error);
            this.isLoading = false;
            // Handle error appropriately
          },
        });
    }
  }

  private completeRegistration() {
    // Second API call
    this.http.get('https://api.npms.io/v2/search?q=scope:angular').subscribe({
      next: (data) => {
        this.router.navigate(['subscription-validated'], {
          state: { email: this.subscribeForm.get('email')?.value },
        });
      },
      error: (error) => {
        console.error('Error completing registration:', error);
        // Handle error appropriately
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }
}
