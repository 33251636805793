<div class="container">
  <h2 class="title-page">Inscription validée</h2>
  <p class="sub-title">
    Un Email de validation vient d'etre envoyé a {{ email }}
  </p>
  <p class="info">veuillez valider l'email pour valider votre compte</p>
  <div class="boutton">
    <a class="btn btn-primary" href="/login"> Me connecter </a>
  </div>
</div>

<!-- <p>Value:</p>
<p>{{ loginForm.value.email }}</p>
<p>{{ loginForm.value.password }}</p> -->
