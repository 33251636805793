<div class="flex flex-column container padding-40" [formGroup]="ticketForm">
  <h3 class="title">
    {{ isEdit ? "Modifier l'Incident" : "Créer un Nouvel Incident" }}
  </h3>

  <mat-stepper [linear]="isLinear" formGroupName="stepsGroup">
    <!-- Step 1: Date et heure de l'incident -->
    <mat-step
      [stepControl]="ticketForm.get('stepsGroup.dateTimeStep')"
      label="Date et heure de l'incident"
    >
      <form [formGroup]="ticketForm.get('stepsGroup.dateTimeStep')">
        <h4>Date et heure</h4>
        <div class="flex flex-row justify-content-space-between">
          <!-- Date Field -->
          <mat-form-field>
            <mat-label>Date de l'incident</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              formControlName="date"
              required
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error
              *ngIf="
                ticketForm.get('stepsGroup.dateTimeStep.date')?.invalid &&
                ticketForm.get('stepsGroup.dateTimeStep.date')?.touched
              "
            >
              La date est requise
            </mat-error>
          </mat-form-field>

          <!-- Time Field -->
          <mat-form-field>
            <mat-label>Heure de l'incident</mat-label>
            <input
              type="text"
              matInput
              [matTimepicker]="timepicker"
              formControlName="time"
              required
            />
            <mat-timepicker-toggle
              matSuffix
              [for]="timepicker"
            ></mat-timepicker-toggle>
            <mat-timepicker
              #timepicker
              mode="input"
              format="24h"
            ></mat-timepicker>
            <mat-error
              *ngIf="
                ticketForm.get('stepsGroup.dateTimeStep.time')?.invalid &&
                ticketForm.get('stepsGroup.dateTimeStep.time')?.touched
              "
            >
              L'heure est requise
            </mat-error>
          </mat-form-field>
        </div>
        <div class="flex justify-content-flex-end margin-top-20">
          <button mat-button matStepperNext>Suivant</button>
        </div>
      </form>
    </mat-step>

    <!-- Step 2: Titre et description -->
    <mat-step
      [stepControl]="ticketForm.get('stepsGroup.titleDescriptionStep')"
      label="Titre et description"
    >
      <form [formGroup]="ticketForm.get('stepsGroup.titleDescriptionStep')">
        <h4>Titre de l'incident</h4>
        <div class="flex flex-column">
          <mat-form-field>
            <mat-label>Donnez un titre à votre incident</mat-label>
            <textarea
              matInput
              formControlName="title"
              required
              rows="3"
            ></textarea>
            <mat-error
              *ngIf="
                ticketForm.get('stepsGroup.titleDescriptionStep.title')
                  ?.invalid &&
                ticketForm.get('stepsGroup.titleDescriptionStep.title')?.touched
              "
            >
              Le titre est requis
            </mat-error>
          </mat-form-field>
        </div>

        <h4>Description</h4>
        <div class="flex flex-column">
          <mat-form-field>
            <mat-label>Écrivez ici votre description de l'incident</mat-label>
            <textarea
              matInput
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="20"
              formControlName="description"
              required
            ></textarea>
            <mat-error
              *ngIf="
                ticketForm.get('stepsGroup.titleDescriptionStep.description')
                  ?.invalid &&
                ticketForm.get('stepsGroup.titleDescriptionStep.description')
                  ?.touched
              "
            >
              La description est requise
            </mat-error>
          </mat-form-field>
        </div>
        <div class="flex justify-content-flex-end margin-top-20">
          <button mat-button matStepperPrevious class="margin-right-10">
            Précédent
          </button>
          <button mat-button matStepperNext>Suivant</button>
        </div>
      </form>
    </mat-step>

    <!-- Step 3: Parking -->
    <mat-step
      [stepControl]="ticketForm.get('stepsGroup.parkingStep')"
      label="Parking"
    >
      <form [formGroup]="ticketForm.get('stepsGroup.parkingStep')">
        <h4>Parking</h4>
        <mat-form-field>
          <mat-label>Sélectionner un parking</mat-label>
          <mat-select formControlName="parking" required>
            <mat-option
              *ngFor="let parking of parkings; trackBy: trackByParking"
              [value]="parking.id"
            >
              {{ parking.name }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              ticketForm.get('stepsGroup.parkingStep.parking')?.invalid &&
              ticketForm.get('stepsGroup.parkingStep.parking')?.touched
            "
          >
            Le parking est requis
          </mat-error>
        </mat-form-field>
        <div class="flex justify-content-flex-end margin-top-20">
          <button mat-button matStepperPrevious class="margin-right-10">
            Précédent
          </button>
          <button mat-button matStepperNext>Suivant</button>
        </div>
      </form>
    </mat-step>

    <!-- Step 4: Status, priorité et type -->
    <mat-step
      [stepControl]="ticketForm.get('stepsGroup.statusPriorityTypeStep')"
      label="Status, priorité et type"
    >
      <form [formGroup]="ticketForm.get('stepsGroup.statusPriorityTypeStep')">
        <div class="flex flex-row justify-content-space-between">
          <div>
            <h4>Status</h4>
            <mat-form-field>
              <mat-label>Status</mat-label>
              <mat-select formControlName="status" required>
                <mat-option
                  *ngFor="let status of statuses; trackBy: trackByStatus"
                  [value]="status.id"
                >
                  {{ status.label }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  ticketForm.get('stepsGroup.statusPriorityTypeStep.status')
                    ?.invalid &&
                  ticketForm.get('stepsGroup.statusPriorityTypeStep.status')
                    ?.touched
                "
              >
                Le status est requis
              </mat-error>
            </mat-form-field>
          </div>

          <div>
            <h4>Priorité</h4>
            <mat-form-field>
              <mat-label>Priorité</mat-label>
              <mat-select formControlName="priority" required>
                <mat-option
                  *ngFor="let priority of priorities; trackBy: trackByPriority"
                  [value]="priority.id"
                >
                  {{ priority.label }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  ticketForm.get('stepsGroup.statusPriorityTypeStep.priority')
                    ?.invalid &&
                  ticketForm.get('stepsGroup.statusPriorityTypeStep.priority')
                    ?.touched
                "
              >
                La priorité est requise
              </mat-error>
            </mat-form-field>
          </div>

          <div>
            <h4>Type d'incident</h4>
            <mat-form-field>
              <mat-label>Type incident</mat-label>
              <mat-select formControlName="categories" multiple required>
                <mat-option
                  *ngFor="let category of categories; trackBy: trackByCategory"
                  [value]="category.id"
                >
                  {{ category.label }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  ticketForm.get('stepsGroup.statusPriorityTypeStep.categories')
                    ?.invalid &&
                  ticketForm.get('stepsGroup.statusPriorityTypeStep.categories')
                    ?.touched
                "
              >
                Le type d'incident est requis
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="flex justify-content-flex-end margin-top-20">
          <button mat-button matStepperPrevious class="margin-right-10">
            Précédent
          </button>
          <button mat-button matStepperNext>Suivant</button>
        </div>
      </form>
    </mat-step>

    <!-- Step 5: Photos, documents -->
    <mat-step
      [stepControl]="ticketForm.get('stepsGroup.filesStep')"
      label="Photos, documents"
    >
      <form [formGroup]="ticketForm.get('stepsGroup.filesStep')">
        <div *ngIf="isEdit" class="files-container">
          <h4 class="mb-6 text-xl font-medium">Fichiers attachés</h4>

          <div class="flex gap-8">
            <!-- Pictures Section -->
            <div class="flex-1">
              <div class="upload-section">
                <div class="section-header">
                  <h5 class="text-lg font-medium text-gray-700">Images</h5>
                  <button
                    mat-fab
                    color="primary"
                    class="upload-fab-button"
                    (click)="fileInput.nativeElement.click()"
                  >
                    <mat-icon>add</mat-icon>
                  </button>
                  <input
                    #fileInput
                    type="file"
                    (change)="onFileSelected($event, 'picture')"
                    accept="image/*"
                    multiple
                    class="hidden"
                  />
                </div>

                <div class="files-grid">
                  <div
                    *ngFor="
                      let picture of data?.pictures;
                      trackBy: trackByFileId
                    "
                    class="file-card"
                  >
                    <div
                      class="image-container"
                      (click)="openFile(picture, 'picture')"
                    >
                      <!-- Show spinner while loading -->
                      <mat-spinner
                        *ngIf="!picture.loaded"
                        diameter="40"
                      ></mat-spinner>

                      <img
                        [src]="getFileUrl(picture.fileId)"
                        [alt]="picture.originalName"
                        class="file-thumbnail"
                        (load)="picture.loaded = true"
                        (error)="picture.error = true"
                        [ngClass]="{ hidden: !picture.loaded }"
                      />

                      <!-- Show icon if image fails to load -->
                      <mat-icon *ngIf="picture.error" class="error-icon"
                        >broken_image</mat-icon
                      >

                      <button
                        mat-mini-fab
                        color="warn"
                        class="delete-button"
                        (click)="deleteFile(picture.fileId, 'picture')"
                      >
                        <mat-icon>close</mat-icon>
                      </button>
                    </div>
                    <span class="file-name">{{ picture.originalName }}</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Documents Section -->
            <div class="flex-1">
              <div class="upload-section">
                <div class="section-header">
                  <h5 class="text-lg font-medium text-gray-700">Documents</h5>
                  <button
                    mat-fab
                    color="primary"
                    class="upload-fab-button"
                    (click)="documentInput.nativeElement.click()"
                  >
                    <mat-icon>add</mat-icon>
                  </button>
                  <input
                    #documentInput
                    type="file"
                    (change)="onFileSelected($event, 'file')"
                    multiple
                    class="hidden"
                  />
                </div>

                <div class="files-grid">
                  <div
                    *ngFor="
                      let document of data?.documents;
                      trackBy: trackByFileId
                    "
                    class="file-card"
                  >
                    <div
                      class="doc-container"
                      (click)="openFile(document, 'file')"
                    >
                      <mat-icon class="doc-icon">description</mat-icon>
                      <button
                        mat-mini-fab
                        color="warn"
                        class="delete-button"
                        (click)="deleteFile(document.fileId, 'file')"
                      >
                        <mat-icon>close</mat-icon>
                      </button>
                    </div>
                    <span class="file-name">{{ document.originalName }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex justify-content-flex-end margin-top-20">
          <button mat-button matStepperPrevious class="margin-right-10">
            Précédent
          </button>
          <button mat-raised-button color="primary" (click)="saveTicket()">
            Valider
          </button>
        </div>
      </form>
    </mat-step>
  </mat-stepper>
</div>
