import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';
import { TranslocoRootModule } from './transloco-root.module';
import { ParkingComponent } from './parking/parking.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { LoginService } from './login/login.service';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { HasRoleDirective } from './auth/role.directive';
import { Role } from './auth/roles.enum';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    TranslocoRootModule,
    ParkingComponent,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    RouterLink,
    RouterLinkActive,
    MatSidenavModule,
    HasRoleDirective,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'], // This should be 'styleUrls' and an array
})
export class AppComponent implements OnInit {
  @ViewChild('sidenav') sidenav!: MatSidenav;
  protected readonly Role = Role; // Add this line
  firstName: string | null = null;
  // role: number | null = null;
  title = 'The bicycle Project !';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private matIconReg: MatIconRegistry
  ) {}
  ngOnInit(): void {
    this.matIconReg.setDefaultFontSetClass('material-symbols-outlined');
    this.loginService.profile$.subscribe((profile) => {
      this.firstName = profile ? profile.firstName : null;
      console.log(profile);
      // this.role = profile ? profile.role : null;
    });

    if (this.isConnected()) {
      this.loginService.fetchUserProfile().subscribe({
        error: () => this.router.navigate(['/login']),
      });
    } else {
      // in case we want to override the guard...
      // this.router.navigate(['/login']);
    }
  }
  isConnected() {
    return this.loginService.isConnected();
  }

  logout(): void {
    localStorage.removeItem('authToken');
    this.router.navigate(['/login']);
  }

  toggleSidenav(): void {
    this.sidenav.toggle();
  }
}
