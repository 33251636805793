import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { Status } from './ticket-status/ticket-status.interface';

export interface Ticket {
  title: string;
  description: string;
  parking: string;
  categories: string[];
  priority: string;
  status: string;
  incidentDate: number; // timestamp
}

export interface TicketResponse {
  items: any[];
  totalItems: number;
}

@Injectable({
  providedIn: 'root',
})
export class TicketManagementService {
  private apiUrl = environment.apiUrl;
  private apiRouteTickets = 'tickets';
  private apiRouteStatus = 'tickets/status';
  private apiRoutePriority = 'tickets/priority';
  private apiRouteCategory = 'tickets/category';

  constructor(private http: HttpClient) {}

  // Create a new ticket
  createTicket(ticket: Ticket): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${this.apiUrl}/${this.apiRouteTickets}`, ticket, {
      headers,
    });
  }

  updateTicket(id, ticket: Ticket): any {
    console.log(id, ticket);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.put(
      `${this.apiUrl}/${this.apiRouteTickets}/${id}`,
      ticket,
      {
        headers,
      }
    );
  }

  uploadFiles(formData: FormData): Observable<any> {
    return this.http.post(`${this.apiUrl}/upload`, formData);
  }

  deleteFile(ticketId: string, fileId: string, type: string): Observable<any> {
    return this.http.delete(
      `${this.apiUrl}/tickets/${ticketId}/file/${fileId}/type/${type}`
    );
  }

  updateStatus(data: Status): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .put<{
        status: Status;
      }>(this.apiUrl + '/' + this.apiRouteStatus, data, { headers })
      .pipe(tap((response) => {}));
  }

  createStatus(data: Status): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .post<{
        status: Status;
      }>(this.apiUrl + '/' + this.apiRouteStatus, data, { headers })
      .pipe(tap((response) => {}));
  }

  updatePriority(data: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .put<{
        priority: any;
      }>(this.apiUrl + '/' + this.apiRoutePriority, data, { headers })
      .pipe(tap((response) => {}));
  }

  createPriority(data: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .post<{
        priority: any;
      }>(this.apiUrl + '/' + this.apiRoutePriority, data, { headers })
      .pipe(tap((response) => {}));
  }

  updateCategory(id, data: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .put<{
        category: any;
      }>(this.apiUrl + '/' + this.apiRouteCategory + '/' + id, data, {
        headers,
      })
      .pipe(tap((response) => {}));
  }

  createCategory(data: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .post<{
        category: any;
      }>(this.apiUrl + '/' + this.apiRouteCategory, data, {
        headers,
      })
      .pipe(tap((response) => {}));
  }

  // Add this method to get parkings
  getParkings(
    page: number = 0,
    size: number = 10,
    sort?: string,
    direction: 'asc' | 'desc' = 'asc'
  ): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString());

    if (sort) {
      params = params.set('sort', sort).set('direction', direction);
    }

    return this.http.get(`${this.apiUrl}/parking`, { params });
  }

  // Get all categories
  getCategories(
    page: number = 0,
    size: number = 10,
    sort?: string,
    direction: 'asc' | 'desc' = 'asc'
  ): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString());

    if (sort) {
      params = params.set('sort', sort).set('direction', direction);
    }

    return this.http.get(`${this.apiUrl}/${this.apiRouteCategory}`, { params });
  }

  // Get all categories
  getStatus(
    page: number = 0,
    size: number = 10,
    sort?: string,
    direction: 'asc' | 'desc' = 'asc'
  ): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString());

    if (sort) {
      params = params.set('sort', sort).set('direction', direction);
    }

    return this.http.get(`${this.apiUrl}/${this.apiRouteStatus}`, { params });
  }

  // Get all priorities
  getPriorities(
    page: number = 0,
    size: number = 10,
    sort?: string,
    direction: 'asc' | 'desc' = 'asc'
  ): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString());

    if (sort) {
      params = params.set('sort', sort).set('direction', direction);
    }

    return this.http.get(`${this.apiUrl}/${this.apiRoutePriority}`, { params });
  }

  // Get a single ticket by ID
  getTicketById(id: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/${this.apiRouteTickets}/${id}`);
  }
}
