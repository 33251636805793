import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IParkingData } from 'src/app/common/management.interfaces';

@Component({
  selector: 'app-parking-address',
  standalone: true,
  imports: [],
  templateUrl: './address.component.html',
  styleUrl: './address.component.css',
})
export class ParkingAddressComponent implements OnChanges {
  ngOnChanges(changes: SimpleChanges): void {
    console.warn('changes', changes);
  }
  @Input() data: IParkingData;
}
