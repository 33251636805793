<div class="container">
  <h3 class="title">
    {{ isEdit ? "Editer un utilisateur" : "Créer un utilisateur" }}
  </h3>

  <form [formGroup]="userForm">
    <mat-form-field>
      <mat-label>Prénom</mat-label>
      <input matInput formControlName="firstName" />
      <mat-error *ngIf="userForm.get('firstName')?.invalid">Required</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Nom</mat-label>
      <input matInput formControlName="lastName" />
      <mat-error *ngIf="userForm.get('lastName')?.invalid">Required</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Email</mat-label>
      <input matInput formControlName="email" />
      <mat-error *ngIf="userForm.get('email')?.invalid"
        >Email invalide</mat-error
      >
    </mat-form-field>

    <mat-form-field>
      <mat-label>Mot de passe</mat-label>
      <input
        matInput
        type="password"
        formControlName="passwordHash"
        placeholder=""
      />
      <mat-error *ngIf="userForm.get('password')?.invalid">
        Doit etre d'au moins 8 caractères et comporter un carete special
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Role</mat-label>
      <mat-select formControlName="roles">
        <mat-option *ngFor="let role of roles" [value]="role.value">
          {{ role.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Groupe de villes</mat-label>
      <mat-select formControlName="cityGroup">
        <mat-option *ngFor="let group of cityGroups" [value]="group.id">
          {{ group.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="userForm.get('cityGroup')?.invalid">
        Le groupe de villes est requis
      </mat-error>
    </mat-form-field>

    <mat-slide-toggle formControlName="isActive">Actif</mat-slide-toggle>

    <div class="actions">
      <button mat-button (click)="onCancel()">Annuler</button>
      <button mat-raised-button color="primary" (click)="saveUser()">
        Save
      </button>
    </div>
  </form>
</div>
